import { FC, ReactNode, useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { useAccountCheck } from 'src/hooks/use-account-check';
import AccountPage from "src/pages/dashboard/account";
import { OnboardingCreateForm } from "src/sections/onboarding/onboarding-create-form";

type TeamAccountGuardProps = {
    children: ReactNode;
}

export const TeamAccountGuard: FC<TeamAccountGuardProps> = (props) => {
    const [isMounted, setIsMounted] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const { isLoading, haveTeam, navigateToCreateAccount, hasFetched } = useAccountCheck();

    useEffect(() => {
        setIsMounted(true);

        // Handle URL search parameter updates here
        if (navigateToCreateAccount && searchParams.get('new') !== 'true') {
            // Create a new URLSearchParams object from the existing parameters
            const newParams = new URLSearchParams(searchParams.toString());
            
            // Update or add the 'new' parameter
            newParams.set('new', 'true');

            // Update the search params in the URL without overwriting other parameters
            setSearchParams(newParams);
        }

        return () => setIsMounted(false);
    }, [navigateToCreateAccount, searchParams, setSearchParams]);

    if (!isMounted) {
        return null;
    }

    if (!haveTeam && hasFetched && !isLoading) {
        return <OnboardingCreateForm />;
    }

    if (navigateToCreateAccount && !isLoading) {
        return <AccountPage />;
    }
    
    return <>{props.children}</>;
};