import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'src/store';
import { thunks } from 'src/thunks/team-group';

export const useTeamBatches = () => {
    const dispatch = useDispatch()
    const teamGroups = useSelector(r => r.teamGroup.listTeamGroups)
    const { organizationId } = useParams()
    useEffect(() => {
        if (!organizationId) return
        dispatch(thunks.listTeamGroups(organizationId))
    }, [dispatch, organizationId]);
    return teamGroups
}