import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "src/store"
import { thunks } from "src/thunks/process-module"

export const useProcessModules = () => {
    const processModules = useSelector(state => state.processModule.processModules)
    const [hasQueried, setHasQueried] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        if (!hasQueried) {
            dispatch(thunks.getProcessModules())
            setHasQueried(true)
        }
    }, [dispatch, hasQueried])
    return processModules.list || []
}