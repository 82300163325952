import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Divider, Drawer, Icon, IconButton, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow, Typography, backdropClasses } from '@mui/material';
import { Box, Stack, styled } from "@mui/system";
import User01 from '@untitled-ui/icons-react/build/esm/User01';
import ShareIconOrganization from '@untitled-ui/icons-react/build/esm/UsersPlus';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import logger from 'src/log/log';
import { Item } from "src/types/file-manager";
import { bytesToSize } from 'src/utils/bytes-to-size';
import { fileApi } from '../../../../api/File/FileApi';
import { UserRecords } from '../../team/team-table';
import { ItemIcon } from '../item-icon';
import { PermissionType } from '../shared-status';

interface ItemDrawerProps {
    item: Item;
    onClose?: () => void;
    open?: boolean;
    users: UserRecords
}

const StyledIcon = styled(Icon)(({ theme }) => ({
    color: theme.palette.primary.main,
}))


const AccordionHeader = ({ text, icon }) => (<Stack direction='row'
    spacing={2}
    sx={{
        alignItems: 'center',
        display: 'flex'
    }}
>
    <StyledIcon>
        {icon}
    </StyledIcon>
    <Typography variant='h6'>{text}</Typography>
</Stack>)

interface AccessItem {
    adminGroups: string[],
    // editorGroups: string[],
    viewerGroups: string[],
    adminUsers: string[],
}

export default function ItemShareDrawer(props: ItemDrawerProps) {
    const { item, onClose, open = false, users } = props;

    const { teamId } = useParams()
    let size = item.size && bytesToSize(item.size);
    const [groupExpanded, setGroupExpanded] = useState(true);
    const [userExpanded, setUserExpanded] = useState(false);



    const [access, setAccess] = useState<AccessItem>({
        adminGroups: [...item?.adminGroups || []],
        viewerGroups: [...item?.viewerGroups || []],
        adminUsers: [...item?.adminUsers || []]
    })

    const handleShareGroup = useCallback((e, type: PermissionType, value: string) => {
        const isChecked = e.target.checked;
        setAccess(a => {
            if (type === 'adminUsers') {
                if (a.adminUsers.includes(value)) {
                    return { ...a, adminUsers: a.adminUsers.filter(u => u !== value) }
                } else {
                    return { ...a, adminUsers: [...new Set([...a.adminUsers, value])] }
                }
            }

            if (type === 'adminGroups') {
                a.viewerGroups = a.viewerGroups.filter(g => g !== value)
            }
            if (type === 'viewerGroups') {
                a.adminGroups = a.adminGroups.filter(g => g !== value)
            }

            if (isChecked) {
                return {
                    ...a,
                    [type]: [...new Set([...a[type], value])]
                }
            } else {
                return {
                    ...a,
                    [type]: a[type].filter(item => item !== value)
                }
            }
        })
    }, [])

    const handleSave = useCallback(async () => {
        if (!(teamId || item.id)) return
        if (typeof teamId !== 'string') return

        const result = await fileApi.updateFileAuthorization(teamId, item.id, access)
        logger.error("result", result)
        if (result.ok) {
            toast.success("Access updated successfully")
            onClose && onClose()
        }
        else {
            toast.error("Access update failed")
        }

    }, [access, item.id, onClose, teamId])

    const PermissionCheckbox = ({ teamId, group, type, checked }: { teamId?: string, group: string, type: PermissionType, checked: boolean }) => {
        if (!teamId) return null
        return (<Checkbox
            checked={checked || false}
            onChange={e => handleShareGroup(e, type, teamId + `-${group}`)} />)
    }

    const content = (
        <div>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                sx={{ p: 3 }}
            >
                <IconButton onClick={onClose}>
                    <SvgIcon fontSize="small">
                        <XIcon />
                    </SvgIcon>
                </IconButton>
            </Stack>
            <Divider></Divider>
            <Box sx={{ px: 3, py: 2 }}>
                <Box
                    sx={{
                        backgroundColor: (theme) => theme.palette.mode === 'dark'
                            ? 'neutral.800'
                            : 'neutral.50',
                        borderColor: (theme) => theme.palette.mode === 'dark'
                            ? 'neutral.500'
                            : 'neutral.300',
                        borderRadius: 1,
                        borderStyle: 'dashed',
                        borderWidth: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        mb: 2,
                        p: 3
                    }}
                >
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        spacing={2}
                        sx={{
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <ItemIcon
                            type={item?.__typename}
                            extension={item.name.split('.').pop()}
                        />
                        <Typography>
                            {item.name}
                        </Typography>
                        <Typography>
                            {size}
                        </Typography>
                    </Stack>

                </Box>
            </Box>
            <Divider />
            <Accordion
                expanded={groupExpanded}>
                <AccordionSummary
                    onClick={e => setGroupExpanded(s => !s)}
                >
                    <AccordionHeader
                        text={"Share with groups in this team"}
                        icon={<ShareIconOrganization />} />
                </AccordionSummary>
                <AccordionDetails>
                    <Stack
                        alignItems="center"
                        direction="column"
                        justifyContent="center"
                        spacing={3}
                        sx={{ p: 1 }}
                    >
                        <Box>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Group</TableCell>
                                        <TableCell title='Can Add, Edit, Delete, View'>Change</TableCell>
                                        {/* <TableCell title='Can Add, Edit, View'>Edit</TableCell> */}
                                        <TableCell title='Can View'>View</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Admin</TableCell>
                                        <TableCell>
                                            <PermissionCheckbox
                                                teamId={teamId}
                                                group='admin'
                                                type='adminGroups'
                                                checked={access.adminGroups.includes(teamId + '-admin')}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <PermissionCheckbox
                                                teamId={teamId}
                                                group='admin'
                                                type='viewerGroups'
                                                checked={access.viewerGroups.includes(teamId + '-admin') || access.adminGroups.includes(teamId + '-admin')}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Editor</TableCell>
                                        <TableCell>
                                            <PermissionCheckbox
                                                teamId={teamId}
                                                group='editor'
                                                type='adminGroups'
                                                checked={access.adminGroups.includes(teamId + '-editor')}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <PermissionCheckbox
                                                teamId={teamId}
                                                group='editor'
                                                type='viewerGroups'
                                                checked={access.viewerGroups.includes(teamId + '-editor') || access.adminGroups.includes(teamId + '-editor')}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Viewer</TableCell>
                                        <TableCell>
                                            <PermissionCheckbox
                                                teamId={teamId}
                                                group='viewer'
                                                type='adminGroups'
                                                checked={access.adminGroups.includes(teamId + '-viewer')}
                                            ></PermissionCheckbox>
                                        </TableCell>
                                        <TableCell>
                                            <PermissionCheckbox
                                                teamId={teamId}
                                                group='viewer'
                                                type='viewerGroups'
                                                checked={access.viewerGroups.includes(teamId + '-viewer') || access.adminGroups.includes(teamId + '-viewer')}
                                            ></PermissionCheckbox>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={userExpanded}>
                <AccordionSummary
                    onClick={e => setUserExpanded(s => !s)}
                >
                    <AccordionHeader
                        text={"Share with users in this team"}
                        icon={<User01 />} />
                </AccordionSummary>
                <AccordionDetails>

                    <Stack
                        alignItems="center"
                        direction="column"
                        justifyContent="center"
                        spacing={1}
                        sx={{ p: 1 }}
                    >
                        <Box>
                            <Typography variant='caption'>
                                The users selected will get admin access to the item.
                            </Typography>
                        </Box>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>role</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    Object.keys(users)
                                        .map((id) => {
                                            const user = users[id];
                                            return (
                                                <TableRow key={user.id}>
                                                    <TableCell>{user.account?.displayName || user.email}</TableCell>
                                                    <TableCell>{user.role}</TableCell>
                                                    <TableCell>
                                                        {
                                                            access.adminUsers.includes(user.id) ? (
                                                                <Button
                                                                    onClick={e => handleShareGroup(e, 'adminUsers', user.id)}
                                                                    size={'small'}>Remove</Button>
                                                            ) : (
                                                                <Button
                                                                    onClick={e => handleShareGroup(e, 'adminUsers', user.id)}
                                                                    size={'small'}>Add</Button>
                                                            )
                                                        }
                                                        {/* <IconButton><ShareIcon /></IconButton> */}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                }
                            </TableBody>
                        </Table>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            {/* 
            <Accordion>
                <AccordionSummary>
                    <AccordionHeader
                        text={"Share with organizations"}
                        icon={<Building01 />} />
                </AccordionSummary>
                <AccordionDetails>
                    <Stack
                        alignItems="center"
                        direction="column"
                        justifyContent="center"
                        spacing={1}
                        sx={{ p: 1 }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    orgs && orgs.map(organization => {
                                        if (!organization) return null
                                        return (
                                            <TableRow key={organization.id}>
                                                <TableCell>{organization.description}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        onClick={e => handleShareOrganization(organization.id)}
                                                    ><ShareIconOrganization /></IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Stack>
                </AccordionDetails>
            </Accordion> */}
            <Stack
                direction={'row'}
                alignContent={'center'}
                alignItems={'center'}
                justifyContent={'end'}
                spacing={2}
                p={3}
            >
                <Box>
                    <Button variant='contained'
                        color='primary'
                        onClick={handleSave}>Save</Button>
                </Box>
            </Stack>
        </div>
    )

    return (
        <Drawer
            anchor="right"
            ModalProps={{
                sx: {
                    [`& .${backdropClasses.root}`]: {
                        background: 'transparent !important'
                    }
                }
            }}
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    maxWidth: '100%',
                    width: 400
                }
            }}
        >
            {content}
        </Drawer>
    )
}

