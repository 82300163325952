import { Button, Divider, IconButton, MenuItem, Stack, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import Trash01 from '@untitled-ui/icons-react/build/esm/Trash01';
import { useFormik } from 'formik';
import { FC, useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from 'src/hooks/use-auth';
import * as Yup from 'yup';
import { ITeamUser } from '../../../api/team/index';


type TeamTableProps = {
    onUserChange: (users: TeamTableDetachedValues[]) => void
    users: TeamTableDetachedValues[],
}


export type TeamTableDetachedValues = {
    email: string
    role: string
}

export type UserRecords = Record<string, ITeamUser>

const schema = Yup.object().shape({
    email: Yup.string().email().required(),
    role: Yup.string().oneOf(["admin", "editor", "viewer"]).required(),
})


export const TeamTableDetached: FC<TeamTableProps> = ({ onUserChange, users: initUsers }) => {

    const [newUser, setNewUser] = useState({ email: "", role: "viewer" })
    const [users, setUsers] = useState<TeamTableDetachedValues[]>(initUsers || [])
    const [lastChange, setLastChange] = useState(JSON.stringify(users))
    const { user } = useAuth()
    useEffect(() => {
        const j = JSON.stringify(users)
        if (lastChange !== j) {
            onUserChange(users)
            setLastChange(j)
        }
    }, [users, onUserChange, lastChange])

    const handleDeleteUsers = useCallback((user) => {
        setUsers(s => s.filter(u => u.email !== user.email))
    }, [])

    const initialValues: TeamTableDetachedValues = {
        email: newUser.email,
        role: newUser.role,
    }

    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, helpers) => {
            helpers.setSubmitting(false);
            const email = values.email.toLowerCase()
            // nothing to add
            if (users.filter(u => u.email === email).length > 0) return;
            // cannot add yourself
            const isOwnUser = email === user?.email?.toLowerCase()
            if (isOwnUser) {
                return toast.error("You cannot add yourself to the team, you are admin")
            }
            setUsers(s => {
                const newUsers = [...s, {
                    email,
                    role: values.role,
                }]
                return newUsers
            })

        }
    })

    const handleRoleChangeOfUser = (user, role: string) => {
        setUsers(s => s.map(u => u.email === user.email ? { ...user, role } : u))
    }



    return (
        <>

            <form
                onSubmit={formik.handleSubmit}
            >
                <Stack
                    direction={'row'}
                    spacing={2}
                    justifyContent={'start'}
                    alignItems={'center'}
                    sx={{ my: 2 }}
                >
                    <TextField
                        name="email"
                        label="Email"
                        style={{ flexGrow: 1 }}
                        value={formik.values.email}
                        error={!!(formik.touched.email && formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    ></TextField>

                    <TextField
                        variant='outlined'
                        select
                        name='role'
                        label='Role'
                        defaultValue={'viewer'}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={formik.touched.role && formik.errors.role}
                    >
                        <MenuItem value={'admin'}>Admin</MenuItem>
                        <MenuItem value={'editor'}>Editor</MenuItem>
                        <MenuItem value={'viewer'}>Viewer</MenuItem>
                    </TextField>
                    <div>
                        <Button
                            variant='contained'
                            type='submit'
                        >Add</Button>
                    </div>
                </Stack>
            </form>
            <Divider></Divider>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        users.map(user => {
                            return (
                                <TableRow key={user.email}>
                                    <TableCell>{user.email}</TableCell>
                                    {/* <TableCell>{user.role}</TableCell> */}
                                    <TableCell>
                                        <TextField
                                            variant='outlined'
                                            select
                                            defaultValue={user.role}
                                            onChange={(e) => handleRoleChangeOfUser(user, e.target.value)}
                                        >
                                            <MenuItem value={'admin'}>Admin</MenuItem>
                                            <MenuItem value={'editor'}>Editor</MenuItem>
                                            <MenuItem value={'viewer'}>Viewer</MenuItem>
                                        </TextField>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            color='error'
                                            onClick={() => handleDeleteUsers(user)}
                                        >
                                            <SvgIcon>
                                                <Trash01></Trash01>
                                            </SvgIcon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
            <Stack
                spacing={2}
                sx={{
                    mx: 4,
                    my: 2
                }}
                alignItems="end"
            >
            </Stack>

        </>
    )
}