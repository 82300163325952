import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Team, TeamModuleConfigurationInput, UpdateTeamMutation } from 'src/API';
import logger from 'src/log/log';
import { UpdateProjectRequest } from '../api/project/index';
import { ITeamRoleUsers, ITeamUserResponse, ManagementRole } from '../api/team/index';
import { StateCookie } from '../cookie/cookie';
import { CrudStatus, crudReducer } from './crud-state-slice';
import { getSelectedTeam } from './sliceHelpers';

interface TeamBaseState {
    listTeams: Team[]
    current?: Team | null
    loading: boolean
    teamManagementGroups: ManagementRole[],
    users: ITeamRoleUsers
}
type TeamState = TeamBaseState & CrudStatus<TeamBaseState>;


type GetTeamAction = PayloadAction<{ teamId?: string, teams: Team[] }>;
type SetTeamAction = PayloadAction<Team>;

type CreateTeamAction = PayloadAction<{
    name: string
}
>
type DeleteTeamAction = PayloadAction<{
    teamId: string
}
>
type AddUserToTeamAction = PayloadAction<{
    teamId: string
    email: string
    role: string
}>

type RemoveUserFromTeamAction = PayloadAction<{
    teamId: string
    userId: string
}>

type RemoveInvitedUserAction = PayloadAction<{
    invitedId: string
}
>
type UpdateTeamPermissionsAction = PayloadAction<{
    payload: any
}
>
type GetManagementGroupsAction = PayloadAction<{
    managementRoles: ManagementRole[]
}
>
type AddManagementGroupForTeamAction = PayloadAction<{
    teamId: string
    role: string
}>

type RemoveManagementForGroupTeamAction = PayloadAction<{
    teamId: string
    role: string
}>

type UpdateProjectInTeam = PayloadAction<{
    teamId?: string
    updatedProject: UpdateProjectRequest
}>

type GetTeamUsersAction = PayloadAction<ITeamUserResponse>

const initialState: TeamState = {
    listTeams: [],
    current: null,
    loading: false,
    teamManagementGroups: [],
    users: {
        admins: [],
        editors: [],
        viewers: [],
        invited: [],
        managers: []
    },
    _status: {}
}

const setCurrentTeam = (state: TeamState, team) => {
    logger.debug("Set team", team)
    state.current = team
}

const reducers = {
    ...crudReducer<TeamState>(),
    setTeam(state: TeamState, action: SetTeamAction): void {
        // save it to cookie
        new StateCookie().setLastTeamId(action?.payload?.id);
        setCurrentTeam(state, action.payload)
    },
    getTeams(state: TeamState, action: GetTeamAction): void {
        if (!state.current && action.payload?.teams?.length > 0) {
            setCurrentTeam(state, getSelectedTeam(action.payload.teams, action.payload.teamId))
        }
        state.listTeams = action.payload.teams;
    },
    updateProjectInTeam(state: TeamState, action: UpdateProjectInTeam) {
        // Take the sent-in teamId or use the current team's id
        const updateTeam = action.payload.teamId || state.current?.id;
        const teamIndex = state.listTeams.findIndex(team => team?.id === updateTeam);
        if (teamIndex === -1) return;
        if (!action.payload?.updatedProject?.id) {
            return;
        }

        // Find the project in the team's projects list and update it
        const updatedTeam = { ...state.listTeams[teamIndex] };
        updatedTeam.projects?.items?.forEach((project, i) => {
            if (project?.id === action.payload.updatedProject.id) {
                updatedTeam.projects!.items[i] = { ...(project as any), ...action.payload.updatedProject };
            }
        });

        // if current update it
        if (updatedTeam.id === state.current?.id) {
            setCurrentTeam(state, action.payload)
        }

        // Update the team in the state
        const updatedListTeams = [...state.listTeams];
        updatedListTeams[teamIndex] = updatedTeam;
        state.listTeams = updatedListTeams


    },
    getTeamUsers(state: TeamState, action: GetTeamUsersAction): void {
        state.users = action.payload.result.users
        Object.values(state.users).forEach(users => {
            users.forEach(u => {
                u.account = action.payload.result.accounts.find(f => f.id === u.id)
            });
        })
    },
    removeBucketFromTeamProject(state: TeamState, action: PayloadAction<{ projectId, bucketId }>): void {
        if (!state.current) return
        const project = state.current?.projects?.items?.find(p => p?.id === action.payload.projectId)
        if (!project) return
        try {
            const buckets = JSON.parse(project.buckets || '[]')
            buckets.splice(buckets.findIndex(b => b.id === action.payload.bucketId), 1)
            project.buckets = JSON.stringify(buckets)
        } catch (error) {
            console.error(error)
        }


    },

    updateTeam(state: TeamState, action: PayloadAction<GraphQLResult<GraphQLQuery<UpdateTeamMutation>> | undefined>): void {
        console.log('action.payload.data', action?.payload?.data)
        if (!action.payload?.data?.updateTeam) {
            return
        }
        const team = action.payload.data.updateTeam
        state.listTeams.forEach(t => {
            if (t.id === team.id) {
                t = team as any
            }
        })
        setCurrentTeam(state, action.payload.data.updateTeam)

    },

    createTeam(state: TeamState, action: CreateTeamAction): void { },
    deleteTeam(state: TeamState, action: DeleteTeamAction): void {
        state.listTeams = state.listTeams.filter(f => f.id === action.payload.teamId)
        if (state.current?.id === action.payload.teamId) {
            state.current = undefined
        }
    },
    addUserToTeam(state: TeamState, action: AddUserToTeamAction): void { },
    removeUserFromTeam(state: TeamState, action: RemoveUserFromTeamAction): void { },
    removeInvitedUser(state: TeamState, action: RemoveInvitedUserAction): void { },
    updateTeamPermissions(state: TeamState, action: UpdateTeamPermissionsAction): void { },
    getManagementGroups(state: TeamState, action: GetManagementGroupsAction): void {
        state.teamManagementGroups = action.payload.managementRoles
    },
    addManagementGroupForTeamSuccess(state: TeamState, action: AddManagementGroupForTeamAction): void {
    },
    addManagementGroupForTeamFailure(state: TeamState, action: AddManagementGroupForTeamAction): void { },
    removeManagementForGroupTeamSuccess(state: TeamState, action: RemoveManagementForGroupTeamAction): void { },
    removeManagementForGroupTeamFailure(state: TeamState, action: RemoveManagementForGroupTeamAction): void { },
    getOrganizationsSuccess(state: TeamState, action: any): void { },
    getOrganizationsFailure(state: TeamState, action: any): void { },

    updateTeamModuleConfiguration(state: TeamState, action: PayloadAction<TeamModuleConfigurationInput[]>): void {
        if (!state.current) return
        console.log("updateTeamModuleConfiguration", action.payload)
        state.current.moduleConfigurations = action.payload as any
    },
};

export const slice = createSlice({
    name: 'Team',
    initialState,
    reducers
});

export const { reducer } = slice;
