export class ListUtils {
    static getSequenceOrZero = (n: number | undefined | null) => {
        if (!n) return 0
        return n
    }
    static sortAsc(a, b) {
        if (a > b) {
            return 1
        }
        if (a < b) {
            return -1
        }
        return 0
    }
    static sortDesc(a, b) {
        if (a < b) {
            return 1
        }
        if (a > b) {
            return -1
        }
        return 0
    }

    static sortDatesAsc(a: string | Date, b: string | Date) {
        return new Date(a).getTime() - new Date(b).getTime()
    }
    static sortDatesDesc(a, b) {
        return new Date(b).getTime() - new Date(a).getTime()
    }
    static onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }
    static onlyUniqueKeyInObject(array, key) {
        if (!Array.isArray(array)) {
            console.error("array", array)
            throw new Error('array is not Array!')
        }
        const result: any[] = [];
        const map = new Map();
        for (const item of array) {
            if (!map.has(item[key])) {
                map.set(item[key], true);    // set any value to Map
                result.push(item);
            }
        }
        return result
    }
    static uniqueObjectsByKey(array, key): any[] {
        return [...new Map(array.map(item =>
            [item[key], item])).values()];
    }

}