import { Box, IconButton, Stack, SvgIcon, Theme, Typography, useMediaQuery } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { useMemo } from 'react';
import { customerColors } from 'src/contexts/settings-context';
import { useSelector } from 'src/store';
import { customerTopNavColors } from 'src/theme/custom/customer-colors';
import { NavColor } from 'src/types/settings';
import { AccountButton } from '../account-button';

const TOP_NAV_HEIGHT: number = 64;
const SIDE_NAV_WIDTH: number = 280;

interface TopNavProps {
  onMobileNavOpen?: () => void;
  sidebarOpen: boolean;
}
const useCssVars = (color: NavColor): Record<string, string> => {
  const theme = useTheme();

  return useMemo(
    (): Record<string, string> => {
      switch (color) {
        case 'uic':
          return customerTopNavColors(color, theme)
        case 'umea':
          return customerTopNavColors(color, theme)
        default: {
          return {
            '--top-nav-color': theme.palette.common.white,
            '--top-nav-bg-color': alpha(theme.palette.background.default, 0.8),
            '--top-nav-bg': theme.palette.background.default
          }
        }
      }
    }, [color, theme])
}


export const TopNav: FC<TopNavProps> = (props) => {
  const { sidebarOpen, onMobileNavOpen, ...other } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const team = useSelector(state => state.team.current)
  const d = customerColors()
  const cssVars = useCssVars(d.navColor)
  return (
    <Box
      component="header"
      sx={{
        ...cssVars,
        color: 'var(--top-nav-color)',
        backdropFilter: 'blur(6px)',
        background: "var(--top-nav-bg)",
        borderBottom: '1px solid var(--top-nav-border-color,none)',
        position: 'sticky',
        left: {
          lg: `${SIDE_NAV_WIDTH}px`
        },
        top: 0,
        width: {
          lg: `calc(100% - ${sidebarOpen ? SIDE_NAV_WIDTH : 0}px)`
        },
        zIndex: (theme) => theme.zIndex.appBar
      }}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          {(!lgUp || !sidebarOpen) && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
          {/* <SearchButton /> */}
          <Typography style={{color:`black`}}>{team?.name}</Typography>
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          {/* <LanguageSwitch /> */}
          {/* <NotificationsButton /> */}
          {/* <ContactsButton /> */}
          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func
};
