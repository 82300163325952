import { Person } from '@mui/icons-material';
import { Drawer, List, ListItemButton, ListItemIcon, ListItemText, SvgIcon, Theme, Typography, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/system';
import Builing01 from '@untitled-ui/icons-react/build/esm/Building01';
import Home02Icon from '@untitled-ui/icons-react/build/esm/Home02';
import StandIcon from '@untitled-ui/icons-react/build/esm/Stand';
import { useSearchParams } from 'react-router-dom';
import Upload04Icon from 'src/icons/untitled-ui/duocolor/upload-04';


type OraganizationSidebarProps = {
    container?: HTMLDivElement | null;
    onClose?: () => void;
    open?: boolean;
}


export enum OrganizationSettingsCategories {
    templates = 'templates',
    canvases = 'canvases',
    teams = 'teams',
    profile = 'profile',
    batches = 'batches',
    files = 'files'
}





export default function OrganizationSidebar(props: OraganizationSidebarProps) {
    const { container, onClose, open, ...other } = props
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const [searchParams, setSearchParams] = useSearchParams()
    const view = searchParams.get('category')



    const content = (<div>
        <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{ p: 2 }}
        >
            <Typography
                variant="h5"
                sx={{ flexGrow: 1 }}
            >
                <List>
                    <ListItemButton selected={view === OrganizationSettingsCategories.profile}
                        onClick={() => setSearchParams({ category: OrganizationSettingsCategories.profile })}>
                        <ListItemIcon>
                            < Builing01 />
                        </ListItemIcon>
                        <ListItemText primary="Profile" />
                    </ListItemButton>
                    <ListItemButton selected={view === OrganizationSettingsCategories.templates}
                        onClick={() => setSearchParams({ category: OrganizationSettingsCategories.templates })}>
                        <ListItemIcon>
                            <Home02Icon />
                        </ListItemIcon>
                        <ListItemText primary="Templates" />
                    </ListItemButton>
                    <ListItemButton selected={view === OrganizationSettingsCategories.canvases}
                        onClick={() => setSearchParams({ category: OrganizationSettingsCategories.canvases })}>
                        <ListItemIcon>
                            <SvgIcon fontSize="small">
                                <StandIcon />
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText primary="Canvases" />
                    </ListItemButton>
                    <ListItemButton selected={view === OrganizationSettingsCategories.teams}
                        onClick={() => setSearchParams({ category: OrganizationSettingsCategories.teams })}>
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText primary="Teams" />
                    </ListItemButton>
                    <ListItemButton selected={view === OrganizationSettingsCategories.batches}
                        onClick={() => setSearchParams({ category: OrganizationSettingsCategories.batches })}>
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText primary="Batches" />
                    </ListItemButton>
                    <ListItemButton selected={view === OrganizationSettingsCategories.files}
                        onClick={() => setSearchParams({ category: OrganizationSettingsCategories.files })}>
                        <ListItemIcon>
                            <SvgIcon fontSize="small">
                                <Upload04Icon />
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText primary="Files" />
                    </ListItemButton>
                </List>
            </Typography>
        </Stack>
    </div>)

    if (mdUp) {
        return (
            <Drawer
                anchor="left"
                open={open}
                PaperProps={{
                    sx: {
                        position: 'relative',
                        width: 200
                    }
                }}
                SlideProps={{ container }}
                variant="persistent"
                {...other}
            >
                {content}
            </Drawer>
        );
    }
    return (
        <Drawer
            anchor="left"
            hideBackdrop
            ModalProps={{
                container,
                sx: {
                    pointerEvents: 'none',
                    position: 'absolute'
                }
            }}
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    maxWidth: '100%',
                    width: 380,
                    pointerEvents: 'auto',
                    position: 'absolute'
                }
            }}
            SlideProps={{ container }}
            variant="temporary"
            {...other}
        >
            {content}
        </Drawer>
    )
}
