import { Organization, UpdateOrganizationInput } from 'src/API';
import PlanvasBaseApi from '../api';
import { API, graphqlOperation } from 'aws-amplify';
import { updateOrganization } from 'src/graphql/mutations';

class OrganizationApi extends PlanvasBaseApi {
    endpoint = "/organization"
    fileEndpoint = '/organization/file'
    folderEndpoint = '/organization/folder'

    async getOrganizations() {
        return super.get(this.endpoint)
    }

    async updateOrganization(request: UpdateOrganizationInput) {
        return API.graphql(graphqlOperation(updateOrganization, { input: request }))
    }

    uploadIcon(file: FormData, organizationId: string) {
        return super.post(this.endpoint + '/icon/' + organizationId, file, {
            'Content-Type': 'multipart/form-data'
        })
    }

    async getFilesForPath(organizationId: string, path: string) {
        return super.get(`${this.fileEndpoint}/` + organizationId, {
            path
        })
    }
    uploadfiles(files: FormData, params: { organizationId: string, path: string }, onUploadProgress) {
        return super.postWithProgress(this.fileEndpoint, files, {
            "Content-Type": "multipart/form-data"
        }, onUploadProgress, {
            params
        })
    }

    changeFolderAuthorization(organizationId: string, teamGroupIds: string[], folderId: string) {
        return super.put(`${this.folderEndpoint}/authorization/${organizationId}`, { teamGroupIds, folderId })
    }

    async deleteFile() { }
    async deleteFolder() { }
    // async createFolder() {
    //     return super.post(this.endpoint + '/folder', )
    // }
    async renameFolder() { }
}

export const organizationApi = new OrganizationApi();