import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Template, ListTemplatesQuery, UpdateTemplateMutation, DeleteTemplateMutation, TeamGroup, ListTeamGroupsQuery } from '../API';
import { GraphQLResult, GraphQLQuery } from '@aws-amplify/api';
import logger from 'src/log/log';

interface TeamGroupsState {
    listTeamGroups: TeamGroup[]
}

const initialState: TeamGroupsState = {
    listTeamGroups: [],
}

type CreateTeamGroupAction = PayloadAction<TeamGroup | undefined | null>
type ListTeamGroupAction = any//PayloadAction<GraphQLResult<GraphQLQuery<ListTeamGroupsQuery>>>
type DeleteTeamGroupAction = PayloadAction<{ id: string }>
type UpdateTeamGroupAction = PayloadAction<TeamGroup>
const reducers = {
    create(state: TeamGroupsState, action: CreateTeamGroupAction): void {
        if (!action.payload) return
        state.listTeamGroups.push(action.payload)
    },
    list(state: TeamGroupsState, action: ListTeamGroupAction): void {
        state.listTeamGroups = action.payload.items
    },
    delete(state: TeamGroupsState, action: DeleteTeamGroupAction): void {
        state.listTeamGroups = state.listTeamGroups.filter(teamGroup => teamGroup.id !== action.payload.id)
    },
    update(state: TeamGroupsState, action: UpdateTeamGroupAction): void {
        for (let i = 0; i < state.listTeamGroups.length; i++) {
            const tg = state.listTeamGroups[i]
            if (tg.id === action.payload?.id) {
                state.listTeamGroups[i] = action.payload
                return
            }
        }
    }
}

export const slice = createSlice({
    name: 'TeamGroup',
    initialState,
    reducers
});

export const { reducer } = slice;
