import { CreateTeamGroupInput, DeleteTeamGroupInput, UpdateTeamGroupInput } from 'src/API';
import { teamGroupApi } from 'src/api/team-group';
import { slice } from '../slices/team-group';
import { AppThunk } from '../store/index';
import toast from 'react-hot-toast';

const createTeamGroup = (params: Omit<CreateTeamGroupInput, 'organizationRole'>): AppThunk => async (dispatch): Promise<void> => {
    const response = await teamGroupApi.create(params)
    dispatch(slice.actions.create(response.data?.createTeamGroup))
    dispatch(thunks.listTeamGroups(params.organizationId))
}

const listTeamGroups = (id: string): AppThunk => async (dispatch, getState): Promise<void> => {
    const response = await teamGroupApi.listByOrganization(id)
    dispatch(slice.actions.list(response.data?.teamGroupByOrganization))
}

const deleteTeamGroup = (organizationId: string, params: DeleteTeamGroupInput): AppThunk => async (dispatch, getState): Promise<void> => {
    const response = await teamGroupApi.delete(organizationId, params);
    dispatch(slice.actions.delete({ id: response.result.id }))
    dispatch(thunks.listTeamGroups(organizationId))

}

const updateTeamGroup = (organizationId: string, params: UpdateTeamGroupInput): AppThunk => async (dispatch, getState): Promise<void> => {
    const response = await teamGroupApi.update(organizationId, params)
    if (!response.ok) {
        toast.error("Failed to update team group")
        return
    }
    dispatch(slice.actions.update(response.result))
    dispatch(thunks.listTeamGroups(organizationId))
}


export const thunks = {
    createTeamGroup,
    listTeamGroups,
    deleteTeamGroup,
    updateTeamGroup
} 