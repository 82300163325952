import { Dialog, DialogContent, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { File, FileDropzone } from 'src/components/file-dropzone';

interface FileUploaderProps {
  onClose?: () => void;
  open?: boolean;
  onUploadProgress?: (progress: number) => void;
  onUpload: (formData: FormData) => any

}

export const FileUploader: FC<FileUploaderProps> = (props) => {
  const { onClose, open = false, onUploadProgress, onUpload } = props;
  const [files, setFiles] = useState<File[]>([]);
  const { teamId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(
    () => {
      setFiles([]);
    },
    [open]
  );

  const handleUpload = useCallback(() => {
    const formData = new FormData();

    if (!teamId) return
    files.forEach(f => {
      if (!f.path) return
      formData.append(f.path, f)
    })

    onUpload(formData)
    // fileApi.uploadfiles(formData, {
    //   teamId,
    //   path: searchParams.get('id') || '/'
    // }, (event) => {
    //   onUploadProgress && onUploadProgress(event.loaded / event.total * 100)
    // })
    onClose && onClose();
  }, [teamId, files, onUpload, onClose])

  const handleDrop = useCallback(
    (newFiles: File[]): void => {
      setFiles((prevFiles) => {
        return [...prevFiles, ...newFiles];
      });
    },
    []
  );

  const handleRemove = useCallback(
    (file: File): void => {
      setFiles((prevFiles) => {
        return prevFiles.filter((_file) => _file.path !== file.path);
      });
    },
    []
  );

  const handleRemoveAll = useCallback(
    (): void => {
      setFiles([]);
    },
    []
  );

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{
          px: 3,
          py: 2
        }}
      >
        <Typography variant="h6">
          Upload Files
        </Typography>
        <IconButton
          color="inherit"
          onClick={onClose}
        >
          <SvgIcon>
            <XIcon />
          </SvgIcon>
        </IconButton>
      </Stack>
      <DialogContent>
        <FileDropzone
          accept={{ '*/*': [] }}
          caption=""
          files={files}
          onDrop={handleDrop}
          onRemove={handleRemove}
          onRemoveAll={handleRemoveAll}
          onUpload={handleUpload}
        />
      </DialogContent>
    </Dialog>
  );
};

FileUploader.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onUploadProgress: PropTypes.func
};
