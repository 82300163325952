import API from '../api';
interface FileUploadItem {
    filename: string;
    filetype: string;
    size: number;
}

class FileAPI extends API {
    fileEndpoint = "/file"
    folderEndpoint = "/folder"

    //  getPermissions() {
    //     return super.get(`${this.fileEndpoint}/permission`)
    // }

    getGetSignedUrl(id: string) {
        return super.get(`${this.fileEndpoint}/sign`, { id })
    }

    // uploadOrganizationFile(file: FormData, organizationId: string) {
    //     return super.post(this.fileEndpoint + '/organization/' + organizationId, file, {
    //         'Content-Type': 'multipart/form-data'
    //     })
    // }

    getFilesForPath(teamId: string, path: string) {
        return super.get(`${this.fileEndpoint}/` + teamId, {
            path
        })
    }

    getSharedFilesForPath(teamId: string, path: string) {
        return super.get(`${this.fileEndpoint}/${teamId}/shared`, {
            path
        })
    }

    deleteFile(keys: string[], teamId: string) {
        return super.delete(this.fileEndpoint, { keys, teamId })
    }

    deleteFolders(ids: string[], teamId: string) {
        return super.delete(this.folderEndpoint, { folderIds: ids, teamId })
    }

    uploadfiles(files: FormData, params: { teamId: string, path: string }, onUploadProgress) {
        return super.postWithProgress(this.fileEndpoint, files, {
            "Content-Type": "multipart/form-data"
        }, onUploadProgress, {
            params
        })
    }

    uploadSharedfiles(files: FormData, params: { teamId: string, path: string }, onUploadProgress) {
        return super.postWithProgress(`${this.fileEndpoint}/shared`, files, {
            "Content-Type": "multipart/form-data"
        }, onUploadProgress, {
            params
        })
    }

    createFolder(path: string, folderName: string, teamId: string) {
        return this.post(this.folderEndpoint, {
            path, teamId, name: folderName
        })
    }
    renameFolder(payload: { id, teamId, name: string }) {
        return this.put(this.folderEndpoint, payload)
    }

    //  updateFolderAuthorization(teamId: string, folderId: string, access: {
    //     groups: string[],
    //     adminUsers: string[],
    // }) {
    //     return this.put(`${this.folderEndpoint}/authorization/${folderId}`, {
    //         teamId,
    //         ...access
    //     })
    // }

    updateFileAuthorization(teamId: string, fileId: string, access: {
        adminGroups: string[],
        // editorGroups: string[],
        viewerGroups: string[],
        adminUsers: string[],
    }) {
        return this.put(`${this.folderEndpoint}/authorization/${fileId}`, {
            teamId,
            ...access
        })
    }
}

export const fileApi = new FileAPI()