import PropTypes from 'prop-types';
import type { FC } from 'react';
import { FileIcon } from 'src/components/file-icon';
import type { ItemType } from 'src/types/file-manager';

interface ItemIconProps {
  extension?: string;
  type: ItemType;
}

export const ItemIcon: FC<ItemIconProps> = (props) => {
  const { type, extension } = props;

  return type === 'Folder'
    ? <img src="/assets/icons/icon-folder.svg" />
    : <FileIcon extension={extension} />;
};

ItemIcon.propTypes = {
  extension: PropTypes.string,
  type: PropTypes.oneOf<ItemType>(['File', 'Folder']).isRequired
};
