import { Box, Stack, Table, TableBody } from '@mui/material';
import PropTypes from 'prop-types';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import { Scrollbar } from 'src/components/scrollbar';
import type { Item } from 'src/types/file-manager';
import { UserRecords } from '../team/team-table';
import { ItemListCard } from './item-list-card';
import { ItemListRow } from './item-list-row';

type View = 'grid' | 'list';

interface ItemListProps {
  count?: number;
  items?: Item[];
  onDelete?: (itemId: string) => void;
  onFavorite?: (itemId: string, value: boolean) => void;
  onOpen?: (item: Item) => void;
  onPageChange?: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onItemChecked?: (item: Item) => void;
  page?: number;
  onShare?: (item: Item) => void;
  rowsPerPage?: number;
  view?: View;
  users: UserRecords
}

export const ItemList: FC<ItemListProps> = (props) => {
  const {
    count = 0,
    items = [],
    onDelete,
    onFavorite,
    onOpen,
    onShare,
    onPageChange = () => { },
    onRowsPerPageChange,
    onItemChecked,
    page = 0,
    rowsPerPage = 0,
    view = 'grid',
    users
  } = props;

  let content: JSX.Element;

  if (view === 'grid') {
    content = (
      <Box
        sx={{
          display: 'grid',
          gap: 3,
          gridTemplateColumns: 'repeat(3, 1fr)'
        }}
      >
        {items.map((item) => (
          <ItemListCard
            onShare={onShare}
            users={users}
            key={item.id}
            item={item}
            onDelete={onDelete}
            onFavorite={onFavorite}
            onOpen={onOpen}
            onItemChecked={onItemChecked}
          />
        ))}
      </Box>
    );
  } else {
    // Negative margin is a fix for the box shadow. The virtual scrollbar cuts it.
    content = (
      <Box sx={{ m: -3 }}>
        <Scrollbar>
          <Box sx={{ p: 3 }}>
            <Table
              sx={{
                minWidth: 600,
                borderCollapse: 'separate',
                borderSpacing: '0 8px'
              }}
            >
              <TableBody>
                {items.map((item) => (
                  <ItemListRow
                    onShare={onShare}
                    users={users}
                    key={item.id}
                    item={item}
                    onDelete={onDelete}
                    onFavorite={onFavorite}
                    onOpen={onOpen}
                    onItemChecked={onItemChecked}
                  />
                ))}
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
      </Box>
    );
  }

  return (
    <Stack spacing={4}>
      {content}
      {/* <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[9, 18]}
      /> */}
    </Stack>
  );
};

ItemList.propTypes = {
  items: PropTypes.array,
  count: PropTypes.number,
  onDelete: PropTypes.func,
  onFavorite: PropTypes.func,
  onOpen: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  users: PropTypes.any,
  view: PropTypes.oneOf<View>(['grid', 'list'])
};
