import { Button, Card, CardContent, Grid, Stack, SvgIcon, Typography } from '@mui/material';
import { Box, Container } from "@mui/system";
import Upload01Icon from '@untitled-ui/icons-react/build/esm/Upload01';
import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { TeamGroup } from 'src/API';
import { organizationApi } from 'src/api/organizations';
import { Seo } from "src/components/seo";
import { useDialog } from 'src/hooks/use-dialog';
import { useGetUsers } from 'src/hooks/use-get-users-in-team';
import { useMounted } from 'src/hooks/use-mounted';
import { useTeamBatches } from 'src/hooks/use-team-batches';
import { useItemsSearch } from 'src/pages/dashboard/file-manager';
import FileManagerBreadcrumb from 'src/sections/dashboard/file-manager/file-manager-breadcrumb';
import { FileUploader } from 'src/sections/dashboard/file-manager/file-uploader';
import { ItemList } from 'src/sections/dashboard/file-manager/item-list';
import { getUserRecords } from 'src/sections/dashboard/team/team-table';
import type { Item } from 'src/types/file-manager';
import OrganizationShareDrawer from '../organization-batches/organization-share-drawer';

type View = 'grid' | 'list';
type SortDir = 'asc' | 'desc';

interface Filters {
    query?: string;
}
interface ItemsSearchState {
    filters: Filters;
    page: number;
    rowsPerPage: number;
    sortBy?: string;
    sortDir?: SortDir;
}

interface ItemsStoreState {
    items: Item[],
    itemsCount: number;
}

type TabValue = 'organization'
const useItemsStore = (tab: TabValue, itemsSearch: ItemsSearchState) => {
    const isMounted = useMounted();
    const { organizationId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    let path = searchParams.get('path')
    const [state, setState] = useState<ItemsStoreState>({
        items: [],
        itemsCount: 0
    });

    const mapFileNotCheckable = ((d: Item) => {
        if (d.__typename === 'File') d.features = { canCheck: false }
        return d
    })

    const handleItemsGet = useCallback(
        async () => {
            try {
                let response
                if (!organizationId) return
                if (!path) path = '/'
                if (tab === 'organization') {
                    response = await organizationApi.getFilesForPath(organizationId, path)
                }
                if (isMounted()) {
                    setState({
                        items: response.result.items.map(mapFileNotCheckable),
                        itemsCount: response.result.length
                    });
                }
            } catch (err) {
                console.error(err);
                toast.error("Failed to get items");
            }
        },
        [path, isMounted, organizationId, tab]
    );

    useEffect(() => {
        setState(s => ({
            ...s,
            items: state.items
                .sort((a, b) => {
                    const sortBy = itemsSearch.sortBy || 'created';
                    const A = new Date(a[sortBy]).getTime()
                    const B = new Date(b[sortBy]).getTime()
                    if (itemsSearch.sortDir === 'desc') {
                        return B - A
                    } else {
                        return A - B
                    }
                })//.map(mapFileNotCheckable)
        }))

    }, [state.items, itemsSearch.sortBy, itemsSearch.sortDir])


    useEffect(
        () => {
            handleItemsGet();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [path, tab]
    );

    const handleDelete = useCallback(
        (): void => {
        },
        []
    );

    const handleFavorite = useCallback(
        (itemId: string, value: boolean): void => {
            setState((prevState) => {
                return {
                    ...prevState,
                    items: prevState.items.map((item) => {
                        if (item.id === itemId) {
                            return {
                                ...item,
                                isFavorite: value
                            };
                        }

                        return item;
                    })
                };
            });
        },
        []
    );

    return {
        handleDelete,
        handleFavorite,
        ...state
    };
};
export default function OrganizationFiles() {
    const isMounted = useMounted();
    const teamUsers = useGetUsers()
    const users = getUserRecords(teamUsers)
    const uploadDialog = useDialog();
    const itemsSearch = useItemsSearch();
    const [searchParams, setSearchParams] = useSearchParams()
    let path = searchParams.get('path')
    const itemsStore = useItemsStore('organization', itemsSearch.state);
    const navigate = useNavigate()
    const batches = useTeamBatches()
    const [checkedItem, setCheckedItems] = useState<Item | undefined>(undefined);
    const [view, setView] = useState<View>('grid');
    const [sharedToTeamGroups, setSharedToTeamGroups] = useState<string[]>([]);
    const [state, setState] = useState<ItemsStoreState>({
        items: [],
        itemsCount: 0
    });
    const shareDrawer = useDialog()

    const { organizationId } = useParams()

    const handleItemsGet = useCallback(
        async () => {
            try {
                // let response
                if (!organizationId) return
                if (!path) path = '/'
                const response = await organizationApi.getFilesForPath(organizationId, path)
                if (isMounted()) {
                    setState({
                        items: response.result.items,
                        itemsCount: response.result.length
                    });
                }
            } catch (err) {
                console.error(err);
                toast.error("Failed to get items");
            }
        },
        [path, isMounted]
    );
    useEffect(
        () => {
            handleItemsGet();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [path]
    );

    const handleUpload = useCallback((formData: FormData) => {
        // Implementation for sharing files
        if (!organizationId) return
        organizationApi.uploadfiles(formData, { organizationId, path: searchParams.get('id') || '/' }, (event) => { })
    }, [organizationId, searchParams]);

    // Implement other functions as needed
    const handleOnShareClick = useCallback(() => {
        // Implementation for sharing files
        if (!organizationId) return
        if (!checkedItem) {
            toast.error('No file selected')
            return
        }
        organizationApi.changeFolderAuthorization(organizationId, sharedToTeamGroups, checkedItem.id)
        shareDrawer.handleClose()
    }, [checkedItem, organizationId, shareDrawer, sharedToTeamGroups]);

    const handleDelete = useCallback((itemId) => {
        // Implementation for deleting items
    }, []);

    const handleItemClick = useCallback((item: Item) => {
        if (item.__typename === 'Folder') {
            navigate(`?category=files&path=${item.relativePath}&id=${item.id}`)
            setCheckedItems(undefined)
        }
    }, [navigate])

    const handleCheckedItem = useCallback((item: Item) => {
        setCheckedItems(item)
    }, [])

    const handleSelectChange = (e, tg: TeamGroup) => {
        const id = e.target.value
        setSharedToTeamGroups(s => {
            const idx = s.indexOf(id)
            const values = [...s]
            if (idx === -1) {
                values.push(id)
            } else {
                values.splice(idx, 1)
            }
            return values
        });
    };


    const filteredItems = itemsStore.items
        .filter(f => {
            if (!itemsSearch.state.filters!.query!) return true
            if (itemsSearch.state.filters!.query!.trim() === '') return true

            return f.name.toLowerCase().includes(itemsSearch.state.filters!.query!.toLowerCase())
        })

    return (
        <>
            <Seo title="File Manager" />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8
                }}
            >
                <Container>
                    <Grid
                        container
                        spacing={{
                            xs: 3,
                            lg: 4
                        }}>
                        <Grid item
                            xs={12}>
                            <Box p={1}>
                                <Card>
                                    <CardContent>
                                        <Stack spacing={4}>
                                            <Typography variant='h6'>Share with batches</Typography>
                                            <Typography>
                                                Here you have the capability to distribute various items among multiple teams, facilitating efficient management and organization of files across diverse batches.
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                        <Grid item
                            xs={12}
                            p={4}>
                            <Stack direction='row'
                                justifyContent={'space-between'}
                                spacing={4}
                            >
                                <div>
                                    <Typography variant='h4'>
                                        File Manager
                                    </Typography>
                                </div>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={2}
                                >
                                    <Button
                                        onClick={uploadDialog.handleOpen}
                                        startIcon={(
                                            <SvgIcon>
                                                <Upload01Icon />
                                            </SvgIcon>
                                        )}
                                        variant="contained"
                                    >
                                        Upload
                                    </Button>
                                </Stack>
                            </Stack>
                            <Grid item
                                xs={12}
                                md={12}>
                                <Stack
                                    spacing={{
                                        xs: 3,
                                        lg: 4
                                    }}>
                                    <FileManagerBreadcrumb additionalParams='category=files'></FileManagerBreadcrumb>
                                    <Stack direction={'row'}>
                                        <div style={{ minHeight: 40 }}>
                                            {<Button
                                                disabled={!Boolean(checkedItem)}
                                                color='secondary'
                                                onClick={shareDrawer.handleOpen}
                                                variant='contained'>Share folder</Button>
                                            }
                                        </div>
                                    </Stack>
                                    <ItemList
                                        onShare={handleOnShareClick}
                                        users={users}
                                        count={itemsStore.itemsCount}
                                        items={filteredItems}
                                        onDelete={handleDelete}
                                        onFavorite={itemsStore.handleFavorite}
                                        onOpen={handleItemClick}
                                        onPageChange={itemsSearch.handlePageChange}
                                        onRowsPerPageChange={itemsSearch.handleRowsPerPageChange}
                                        page={itemsSearch.state.page}
                                        onItemChecked={handleCheckedItem}
                                        rowsPerPage={itemsSearch.state.rowsPerPage}
                                        view={view}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>

            </Box >
            <FileUploader
                onUpload={handleUpload}
                onClose={uploadDialog.handleClose}
                open={uploadDialog.open}
            />
            {checkedItem && <OrganizationShareDrawer
                onClose={shareDrawer.handleClose}
                open={shareDrawer.open}
                batches={batches}
                selectedTeams={sharedToTeamGroups}
                onSelectedTeamChange={handleSelectChange}
                item={checkedItem}
                onShareClick={handleOnShareClick}
            ></OrganizationShareDrawer>}
        </>
    )
}
