import { faDiagramProject } from '@fortawesome/free-solid-svg-icons';
import { SvgIcon } from '@mui/material';
import CalendarIcon from '@untitled-ui/icons-react/build/esm/Calendar';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import ReceiptCheckIcon from '@untitled-ui/icons-react/build/esm/ReceiptCheck';
import Settings01 from '@untitled-ui/icons-react/build/esm/Settings01';
import StandIcon from '@untitled-ui/icons-react/build/esm/Stand';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { isExcludedFeature } from 'src/config';
import { usePermissionGuard } from 'src/guards/organization-guard';
import { useProcessModules } from 'src/hooks/use-process-modules';
import { useUserOrganizations } from 'src/hooks/use-user-organizations';
import FaIcon from 'src/icons/font-awesome/FaIcon';
import CheckDone01Icon from 'src/icons/untitled-ui/duocolor/check-done-01';
import HomeSmileIcon from 'src/icons/untitled-ui/duocolor/home-smile';
import Upload04Icon from 'src/icons/untitled-ui/duocolor/upload-04';
import { tokens } from 'src/locales/tokens';
import { paths } from 'src/paths';
import { OrganizationObject } from 'src/slices/user';
import { Project } from '../../API';
import { useGetTeams } from '../../hooks/use-get-teams';
export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {
  const { t } = useTranslation();
  const userOrganizations = useUserOrganizations()
  const { team } = useGetTeams()
  const modules = useProcessModules()
  const projects: Project[] = (team?.current?.projects?.items || []).filter((p): p is Project => p != null);
  const { teamId, projectId } = useParams()
  const permissons = usePermissionGuard({
    permissions: ['admin']
  })

  const isOrganization = Boolean(userOrganizations.length)

  const sections = useMemo(
    () => {
      const currentTeam = teamId || team.current?.id || ""
      const pId = projectId || team.current?.projects?.items[0]?.id || ""
      return [
        {
          items: [
            ...(isOrganization ? [{
              title: t(tokens.nav.calendar),
              path: paths.dashboard.calendar,
              icon: (
                <SvgIcon fontSize="small">
                  <CalendarIcon />
                </SvgIcon>
              )
            }] : []),
            {
              title: t(tokens.nav.overview),
              path: paths.team.overview(currentTeam),
              icon: (<SvgIcon fontSize="small">
                <HomeSmileIcon></HomeSmileIcon>
              </SvgIcon>)
            },
            {
              title: t(tokens.nav.projects),
              subheader: t(tokens.nav.projects),
              path: paths.projects.root(currentTeam),
              icon: (<FaIcon
                title={t(tokens.nav.projects)}
                icon={faDiagramProject} />),
              items: [
                {
                  title: 'Create New',
                  path: paths.projects.index(currentTeam, "new"),
                  label: (<SvgIcon style={{ display: 'flex' }}
                    fontSize="small">
                    <PlusIcon></PlusIcon>
                  </SvgIcon>)
                },
                ...projects.map((project: Project) => ({
                  title: project!.name,
                  path: paths.projects.index(currentTeam, project.id),
                }))]
            },
          ]
        },
        {
          subheader: t(tokens.nav.tools),
          items: [
            {
              title: "Canvas",
              path: paths.canvas.overview(currentTeam, pId),
              icon: (<SvgIcon fontSize="small">
                <StandIcon />
              </SvgIcon>)
            },
            ...(isExcludedFeature('kanban') ? [] : [{
              title: t(tokens.nav.kanban),
              path: paths.kanban.index(currentTeam, pId),
              icon: (
                <SvgIcon fontSize="small">
                  <CheckDone01Icon />
                </SvgIcon>
              )
            }]),
            ...((modules || []).length > 0 ?
              modules.filter(d => Boolean(d)).map((module) => ({
                title: module?.name,
                path: paths.modules.index(currentTeam, module?.id),
                icon: (<SvgIcon fontSize="small">
                  <CheckDone01Icon />
                </SvgIcon>)
              }))
              : []) as any,

            ...(isExcludedFeature('irl') ? [] : [{
              title: "Innovation Readiness",
              path: paths.track.innovationReadiness(currentTeam, pId),
              // path: paths.projects.index,
              icon: (<SvgIcon fontSize="small">
                <ReceiptCheckIcon />
              </SvgIcon>)
            }])]
        },
        {
          items: [
            ...(isExcludedFeature('filemanager') ? [] : [{
              title: t(tokens.nav.fileManager),
              path: paths.files.index(currentTeam),
              icon: (
                <SvgIcon fontSize="small">
                  <Upload04Icon />
                </SvgIcon>
              )
            }]),
            // {
            //   title: t(tokens.nav.chat),
            //   path: paths.chat.index(currentTeam),
            //   icon: (<SvgIcon fontSize="small">
            //     <MessageChatSquareIcon />
            //   </SvgIcon>)
            // },
          ]
        },
        ...(permissons.isAdmin ? [{
          subheader: t(tokens.nav.settings),
          items: [{
            title: "Team Settings",
            path: paths.settings.team(currentTeam),
            icon: (<SvgIcon fontSize="small">
              <Settings01></Settings01>
            </SvgIcon>)
          }
          ]
        }] : []),
        ...(Boolean(userOrganizations.length) ? [
          {
            subheader: t(tokens.nav.administration),
            items: [
              ...userOrganizations.map((organization: OrganizationObject) => ({
                title: organization.name,
                path: paths.settings.organization(organization.id),
                icon: (<SvgIcon fontSize="small">
                  <Settings01></Settings01>
                </SvgIcon>)
              }))
            ]
          }
        ] : []),

        // {
        //   subheader: t(tokens.nav.settings),
        //   items: [{
        //     title: "Team Settings",
        //     path: paths.settings.team(currentTeam),
        //     icon: (<SvgIcon fontSize="small">
        //       <Settings01></Settings01>
        //     </SvgIcon>)
        //   }]
        // },
        // {
        //   items: [
        //     {
        //       title: t(tokens.nav.overview),
        //       path: paths.dashboard.index,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <HomeSmileIcon />
        //         </SvgIcon>
        //       )
        //     },
        //     {
        //       title: t(tokens.nav.analytics),
        //       path: paths.dashboard.analytics,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <BarChartSquare02Icon />
        //         </SvgIcon>
        //       )
        //     },
        //     {
        //       title: t(tokens.nav.ecommerce),
        //       path: paths.dashboard.ecommerce,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <LineChartUp04Icon />
        //         </SvgIcon>
        //       )
        //     },
        //     {
        //       title: t(tokens.nav.crypto),
        //       path: paths.dashboard.crypto,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <CurrencyBitcoinCircleIcon />
        //         </SvgIcon>
        //       ),
        //       label: (
        //         <Chip
        //           color="primary"
        //           label="New"
        //           size="small"
        //         />
        //       )
        //     },
        //     {
        //       title: t(tokens.nav.account),
        //       path: paths.dashboard.account,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <HomeSmileIcon />
        //         </SvgIcon>
        //       )
        //     }
        //   ]
        // },
        // {
        //   subheader: t(tokens.nav.concepts),
        //   items: [
        //     {
        //       title: t(tokens.nav.customers),
        //       path: paths.dashboard.customers.index,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <Users03Icon />
        //         </SvgIcon>
        //       ),
        //       items: [
        //         {
        //           title: t(tokens.nav.list),
        //           path: paths.dashboard.customers.index
        //         },
        //         {
        //           title: t(tokens.nav.details),
        //           path: paths.dashboard.customers.details
        //         },
        //         {
        //           title: t(tokens.nav.edit),
        //           path: paths.dashboard.customers.edit
        //         }
        //       ]
        //     },
        //     {
        //       title: t(tokens.nav.productList),
        //       path: paths.dashboard.products.index,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <ShoppingBag03Icon />
        //         </SvgIcon>
        //       ),
        //       items: [
        //         {
        //           title: t(tokens.nav.list),
        //           path: paths.dashboard.products.index
        //         },
        //         {
        //           title: t(tokens.nav.create),
        //           path: paths.dashboard.products.create
        //         }
        //       ]
        //     },
        //     {
        //       title: t(tokens.nav.orderList),
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <ShoppingCart01Icon />
        //         </SvgIcon>
        //       ),
        //       path: paths.dashboard.orders.index,
        //       items: [
        //         {
        //           title: t(tokens.nav.list),
        //           path: paths.dashboard.orders.index
        //         },
        //         {
        //           title: t(tokens.nav.details),
        //           path: paths.dashboard.orders.details
        //         }
        //       ]
        //     },
        //     {
        //       title: t(tokens.nav.invoiceList),
        //       path: paths.dashboard.invoices.index,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <ReceiptCheckIcon />
        //         </SvgIcon>
        //       ),
        //       items: [
        //         {
        //           title: t(tokens.nav.list),
        //           path: paths.dashboard.invoices.index
        //         },
        //         {
        //           title: t(tokens.nav.details),
        //           path: paths.dashboard.invoices.details
        //         }
        //       ]
        //     },
        //     {
        //       title: t(tokens.nav.logistics),
        //       path: paths.dashboard.logistics.index,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <Truck01Icon />
        //         </SvgIcon>
        //       ),
        //       items: [
        //         {
        //           title: t(tokens.nav.dashboard),
        //           path: paths.dashboard.logistics.index
        //         },
        //         {
        //           title: t(tokens.nav.fleet),
        //           path: paths.dashboard.logistics.fleet
        //         }
        //       ]
        //     },
        //     {
        //       title: t(tokens.nav.academy),
        //       path: paths.dashboard.academy.index,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <GraduationHat01Icon />
        //         </SvgIcon>
        //       ),
        //       items: [
        //         {
        //           title: t(tokens.nav.dashboard),
        //           path: paths.dashboard.academy.index
        //         },
        //         {
        //           title: t(tokens.nav.course),
        //           path: paths.dashboard.academy.courseDetails
        //         }
        //       ]
        //     },
        //     {
        //       title: t(tokens.nav.jobList),
        //       path: paths.dashboard.jobs.index,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <Building04Icon />
        //         </SvgIcon>
        //       ),
        //       items: [
        //         {
        //           title: t(tokens.nav.browse),
        //           path: paths.dashboard.jobs.index
        //         },
        //         {
        //           title: t(tokens.nav.details),
        //           path: paths.dashboard.jobs.companies.details
        //         },
        //         {
        //           title: t(tokens.nav.create),
        //           path: paths.dashboard.jobs.create
        //         }
        //       ]
        //     },
        //     {
        //       title: t(tokens.nav.socialMedia),
        //       path: paths.dashboard.social.index,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <Share07Icon />
        //         </SvgIcon>
        //       ),
        //       items: [
        //         {
        //           title: t(tokens.nav.profile),
        //           path: paths.dashboard.social.profile
        //         },
        //         {
        //           title: t(tokens.nav.feed),
        //           path: paths.dashboard.social.feed
        //         }
        //       ]
        //     },
        //     {
        //       title: t(tokens.nav.blog),
        //       path: paths.dashboard.blog.index,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <LayoutAlt02Icon />
        //         </SvgIcon>
        //       ),
        //       items: [
        //         {
        //           title: t(tokens.nav.postList),
        //           path: paths.dashboard.blog.index
        //         },
        //         {
        //           title: t(tokens.nav.postDetails),
        //           path: paths.dashboard.blog.postDetails
        //         },
        //         {
        //           title: t(tokens.nav.postCreate),
        //           path: paths.dashboard.blog.postCreate
        //         }
        //       ]
        //     },
        //     {
        //       title: t(tokens.nav.fileManager),
        //       path: paths.files.index(currentTeam),
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <Upload04Icon />
        //         </SvgIcon>
        //       )
        //     },
        //     // {
        //     //   title: t(tokens.nav.kanban),
        //     //   path: paths.dashboard.kanban,
        //     //   icon: (
        //     //     <SvgIcon fontSize="small">
        //     //       <CheckDone01Icon />
        //     //     </SvgIcon>
        //     //   )
        //     // },
        //     {
        //       title: t(tokens.nav.mail),
        //       path: paths.dashboard.mail,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <Mail03Icon />
        //         </SvgIcon>
        //       )
        //     },
        //     {
        //       title: t(tokens.nav.chat),
        //       path: paths.dashboard.chat,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <MessageChatSquareIcon />
        //         </SvgIcon>
        //       )
        //     },
        //     {
        //       title: t(tokens.nav.calendar),
        //       path: paths.dashboard.calendar,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <CalendarIcon />
        //         </SvgIcon>
        //       )
        //     }
        //   ]
        // },
        // {
        //   subheader: t(tokens.nav.pages),
        //   items: [
        //     {
        //       title: t(tokens.nav.auth),
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <Lock01Icon />
        //         </SvgIcon>
        //       ),
        //       items: [
        //         {
        //           title: t(tokens.nav.login),
        //           items: [
        //             {
        //               title: 'Classic',
        //               path: paths.authDemo.login.classic
        //             },
        //             {
        //               title: 'Modern',
        //               path: paths.authDemo.login.modern
        //             }
        //           ]
        //         },
        //         {
        //           title: t(tokens.nav.register),
        //           items: [
        //             {
        //               title: 'Classic',
        //               path: paths.authDemo.register.classic
        //             },
        //             {
        //               title: 'Modern',
        //               path: paths.authDemo.register.modern
        //             }
        //           ]
        //         },
        //         {
        //           title: t(tokens.nav.forgotPassword),
        //           items: [
        //             {
        //               title: 'Classic',
        //               path: paths.authDemo.forgotPassword.classic
        //             },
        //             {
        //               title: 'Modern',
        //               path: paths.authDemo.forgotPassword.modern
        //             }
        //           ]
        //         },
        //         {
        //           title: t(tokens.nav.resetPassword),
        //           items: [
        //             {
        //               title: 'Classic',
        //               path: paths.authDemo.resetPassword.classic
        //             },
        //             {
        //               title: 'Modern',
        //               path: paths.authDemo.resetPassword.modern
        //             }
        //           ]
        //         },
        //         {
        //           title: t(tokens.nav.verifyCode),
        //           items: [
        //             {
        //               title: 'Classic',
        //               path: paths.authDemo.verifyCode.classic
        //             },
        //             {
        //               title: 'Modern',
        //               path: paths.authDemo.verifyCode.modern
        //             }
        //           ]
        //         }
        //       ]
        //     },
        //     {
        //       title: t(tokens.nav.pricing),
        //       path: paths.pricing,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <CreditCard01Icon />
        //         </SvgIcon>
        //       )
        //     },
        //     {
        //       title: t(tokens.nav.checkout),
        //       path: paths.checkout,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <LogOut01Icon />
        //         </SvgIcon>
        //       )
        //     },
        //     {
        //       title: t(tokens.nav.contact),
        //       path: paths.contact,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <Mail04Icon />
        //         </SvgIcon>
        //       )
        //     },
        //     {
        //       title: t(tokens.nav.error),
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <XSquareIcon />
        //         </SvgIcon>
        //       ),
        //       items: [
        //         {
        //           title: '401',
        //           path: paths['401']
        //         },
        //         {
        //           title: '404',
        //           path: paths['404']
        //         },
        //         {
        //           title: '500',
        //           path: paths['500']
        //         }
        //       ]
        //     }
        //   ]
        // },
        // {
        //   subheader: 'Misc',
        //   items: [
        //     {
        //       title: 'Level 0',
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <AlignLeft02Icon />
        //         </SvgIcon>
        //       ),
        //       items: [
        //         {
        //           title: 'Level 1a',
        //           items: [
        //             {
        //               title: 'Level 2a',
        //               items: [
        //                 {
        //                   title: 'Level 3a'
        //                 },
        //                 {
        //                   title: 'Level 3b',
        //                   disabled: true
        //                 }
        //               ]
        //             },
        //             {
        //               title: 'Level 2b'
        //             }
        //           ]
        //         },
        //         {
        //           title: 'Level 1b'
        //         }
        //       ]
        //     },
        //     {
        //       title: 'Disabled',
        //       disabled: true,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <XSquareIcon />
        //         </SvgIcon>
        //       )
        //     },
        //     {
        //       title: 'Label',
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <File01Icon />
        //         </SvgIcon>
        //       ),
        //       label: (
        //         <Chip
        //           color="primary"
        //           label="New"
        //           size="small"
        //         />
        //       )
        //     },
        //     {
        //       title: 'Blank',
        //       path: paths.dashboard.blank,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <File01Icon />
        //         </SvgIcon>
        //       )
        //     },
        //     {
        //       title: 'External Link',
        //       path: 'https://devias.io',
        //       external: true,
        //       icon: (
        //         <SvgIcon fontSize="small">
        //           <File01Icon />
        //         </SvgIcon>
        //       )
        //     }
        //   ]
        // }
      ];
    },
    [teamId, team, projectId, t, projects, permissons.isAdmin, userOrganizations, modules]
  );
  // if (permissons.isAdmin) {
  //   sections.push({
  //     subheader: t(tokens.nav.settings),
  //     items: [{
  //       title: "Team Settings",
  //       path: paths.settings.team(currentTeam),
  //       icon: (<SvgIcon fontSize="small">
  //         <Settings01></Settings01>
  //       </SvgIcon>)
  //     }
  //     ]
  //   } as any)

  // }

  // if (userOrganizations.length > 0) {
  //   sections.push({
  //     subheader: t(tokens.nav.administration),
  //     items: [
  //       ...userOrganizations.map((organization: OrganizationObject) => ({
  //         title: organization.name,
  //         path: paths.settings.organization(organization.id),
  //         icon: (<SvgIcon fontSize="small">
  //           <Settings01></Settings01>
  //         </SvgIcon>)
  //       }))
  //     ]
  //   } as any)
  // }
  if (team.listTeams.length === 0) return []
  return sections
};
