import { Project, Team } from 'src/API';
import { slice as kanbanSlice } from 'src/slices/kanban';
import { SetProjectState, slice } from 'src/slices/project';
import type { AppThunk } from 'src/store';
import { thunks as teamThunk } from 'src/thunks/team';
import { canvasApi } from '../api/canvas/index';
import { projectApi } from '../api/project';
import { CreateProjectRequest, DeleteProjectRequest, UpdateProjectRequest } from '../api/project/index';
import { getInitialProjectFromTeams } from '../slices/sliceHelpers';

type GetProjectParams = {
  id: string
}


const getProject = (params: GetProjectParams): AppThunk => async (dispatch): Promise<void> => {
  const response = await projectApi.getProject(params);
  const r = response?.data?.getProject
  if (!r) return
  dispatch(slice.actions.getProject(r as Project));
};

const createProject = (params: CreateProjectRequest): AppThunk => async (dispatch): Promise<any> => {
  let res: {
    success: boolean,
    error?: string
  } = {
    success: false,
    error: undefined
  }

  if (!params.teamId) {
    res.error = 'teamId is required'
    dispatch(slice.actions.createProject(res))
    return
  }

  try {
    const response = await projectApi.createProject(params);
    res.success = response.ok || false
    if (!res.success) {
      res.error = response.message
    }
  } catch (error) {
    res.error = error.message
  }

  dispatch(slice.actions.createProject(res))
}

const deleteProject = (params: DeleteProjectRequest): AppThunk => async (dispatch): Promise<void> => {
  const response = await projectApi.deleteProject(params);
  dispatch(teamThunk.getTeams())
  dispatch(slice.actions.deleteProject(response))
}

const updateProject = (params: UpdateProjectRequest): AppThunk => async (dispatch): Promise<void> => {
  const response = await projectApi.updateProject(params)
  dispatch(getProject({ id: params.id! }))
}


const setCurrentProject = (payload: Omit<SetProjectState, "isInitial">): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setCurrentProject({ ...payload, isInitial: false }))
}

const setInitialProjectFromTeams = (teams: Team[]): AppThunk => async (dispatch): Promise<void> => {
  const project = getInitialProjectFromTeams(teams)
  if (project) {
    dispatch(slice.actions.setCurrentProject({ project, isInitial: true }))
    dispatch(kanbanSlice.actions.setColumnIds(project?.buckets))
  }
}

const deleteCanvas = (canvasId: string): AppThunk => async (dispatch): Promise<void> => {
  const response = await canvasApi.deleteCanvas({ canvasId });
  dispatch(slice.actions.deleteCanvas({ canvasId }))
}

const setIRLLevel = (projectId: string, readiness: string): AppThunk => async (dispatch): Promise<void> => {
  const response = await projectApi.updateProject({
    id: projectId as any,
    readiness: readiness as any
  });
  dispatch(getProject({ id: projectId }))
}

export const thunks = {
  getProject,
  createProject,
  deleteProject,
  // updateProjectName,
  updateProject,
  setCurrentProject,
  setInitialProjectFromTeams,
  deleteCanvas,
  setIRLLevel
};
