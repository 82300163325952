import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { TeamGroup } from 'src/API';
import { DialogController } from 'src/hooks/use-dialog';
import OrganizationSelectTeam from './organization-select-team';

type OrganizationBatchesEditModalProps = {
    dialog: DialogController<TeamGroup>,
    selectedTeams: (string | null)[] | null | undefined,
    teamsResult: any, // TeamState
    onSave: (id: string, teamIds: string[]) => void,
}

export default function OrganizationBatchesEditModal({ dialog, selectedTeams, teamsResult, onSave }: OrganizationBatchesEditModalProps) {
    const [selection, setSelection] = useState<(string | null)[]>(selectedTeams || [])

    const handleSelectedTeamChange = useCallback(
        (selection) => {
            setSelection(selection)
        }, [setSelection])

    const handleOnSave = useCallback(() => {
        if (!dialog.data) return
        onSave?.(dialog.data.id, selection.filter(Boolean) as string[])
    }, [dialog.data, onSave, selection])

    useEffect(() => {
        console.log("dialog.data.teamIds", dialog)
    }, [dialog])

    // if (!dialog.data || !dialog.data.teamIds) return null

    const teamIds: string[] = dialog.data?.teamIds ? dialog.data.teamIds.filter(Boolean) as string[] : []
    return (
        <Dialog
            open={dialog.open}
            onClose={dialog.handleClose}
            fullWidth
        >
            <DialogContent>
                {teamIds && <OrganizationSelectTeam
                    defaultSelectedTeams={teamIds}
                    onSelectionChanged={handleSelectedTeamChange}
                    allTeams={teamsResult.listTeams}></OrganizationSelectTeam>}
            </DialogContent>
            <DialogActions>
                <Button onClick={dialog.handleClose}>
                    Close
                </Button>
                <Button onClick={handleOnSave}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}
