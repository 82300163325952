/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const customUpdateTeamModuleConfiguration = /* GraphQL */ `mutation CustomUpdateTeamModuleConfiguration($input: CustomUpdateTeamInput!) {
  customUpdateTeamModuleConfiguration(input: $input) {
    id
    name
    projects {
      nextToken
      __typename
    }
    createdAt
    fileUrl
    image {
      fileKey
      fileSize
      url
      __typename
    }
    updatedAt
    owner
    teamFlags
    description
    moduleConfigurations {
      processModuleID
      processModuleName
      processModuleDescription
      enabled
      __typename
    }
    teamSize
    phoneNumber
    valueProposition
    address
    color
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CustomUpdateTeamModuleConfigurationMutationVariables,
  APITypes.CustomUpdateTeamModuleConfigurationMutation
>;
export const createCanvas = /* GraphQL */ `mutation CreateCanvas(
  $input: CreateCanvasInput!
  $condition: ModelCanvasConditionInput
) {
  createCanvas(input: $input, condition: $condition) {
    id
    name
    lowercaseName
    teamId
    canvasTiles {
      nextToken
      __typename
    }
    projectId
    project {
      id
      name
      lowercaseName
      teamId
      readiness
      createdAt
      updatedAt
      buckets
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    createdAt
    updatedAt
    owner
    channelId
    properties
    ownerEmail
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCanvasMutationVariables,
  APITypes.CreateCanvasMutation
>;
export const updateCanvas = /* GraphQL */ `mutation UpdateCanvas(
  $input: UpdateCanvasInput!
  $condition: ModelCanvasConditionInput
) {
  updateCanvas(input: $input, condition: $condition) {
    id
    name
    lowercaseName
    teamId
    canvasTiles {
      nextToken
      __typename
    }
    projectId
    project {
      id
      name
      lowercaseName
      teamId
      readiness
      createdAt
      updatedAt
      buckets
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    createdAt
    updatedAt
    owner
    channelId
    properties
    ownerEmail
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCanvasMutationVariables,
  APITypes.UpdateCanvasMutation
>;
export const deleteCanvas = /* GraphQL */ `mutation DeleteCanvas(
  $input: DeleteCanvasInput!
  $condition: ModelCanvasConditionInput
) {
  deleteCanvas(input: $input, condition: $condition) {
    id
    name
    lowercaseName
    teamId
    canvasTiles {
      nextToken
      __typename
    }
    projectId
    project {
      id
      name
      lowercaseName
      teamId
      readiness
      createdAt
      updatedAt
      buckets
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    createdAt
    updatedAt
    owner
    channelId
    properties
    ownerEmail
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCanvasMutationVariables,
  APITypes.DeleteCanvasMutation
>;
export const createCanvasTile = /* GraphQL */ `mutation CreateCanvasTile(
  $input: CreateCanvasTileInput!
  $condition: ModelCanvasTileConditionInput
) {
  createCanvasTile(input: $input, condition: $condition) {
    id
    canvasId
    tasks
    teamId
    message
    position
    title
    createdAt
    updatedAt
    updatedBy
    info
    owner
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCanvasTileMutationVariables,
  APITypes.CreateCanvasTileMutation
>;
export const updateCanvasTile = /* GraphQL */ `mutation UpdateCanvasTile(
  $input: UpdateCanvasTileInput!
  $condition: ModelCanvasTileConditionInput
) {
  updateCanvasTile(input: $input, condition: $condition) {
    id
    canvasId
    tasks
    teamId
    message
    position
    title
    createdAt
    updatedAt
    updatedBy
    info
    owner
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCanvasTileMutationVariables,
  APITypes.UpdateCanvasTileMutation
>;
export const deleteCanvasTile = /* GraphQL */ `mutation DeleteCanvasTile(
  $input: DeleteCanvasTileInput!
  $condition: ModelCanvasTileConditionInput
) {
  deleteCanvasTile(input: $input, condition: $condition) {
    id
    canvasId
    tasks
    teamId
    message
    position
    title
    createdAt
    updatedAt
    updatedBy
    info
    owner
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCanvasTileMutationVariables,
  APITypes.DeleteCanvasTileMutation
>;
export const createTemplate = /* GraphQL */ `mutation CreateTemplate(
  $input: CreateTemplateInput!
  $condition: ModelTemplateConditionInput
) {
  createTemplate(input: $input, condition: $condition) {
    id
    name
    description
    tiles
    sequence
    createdAt
    aiEnabled
    updatedAt
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTemplateMutationVariables,
  APITypes.CreateTemplateMutation
>;
export const updateTemplate = /* GraphQL */ `mutation UpdateTemplate(
  $input: UpdateTemplateInput!
  $condition: ModelTemplateConditionInput
) {
  updateTemplate(input: $input, condition: $condition) {
    id
    name
    description
    tiles
    sequence
    createdAt
    aiEnabled
    updatedAt
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTemplateMutationVariables,
  APITypes.UpdateTemplateMutation
>;
export const deleteTemplate = /* GraphQL */ `mutation DeleteTemplate(
  $input: DeleteTemplateInput!
  $condition: ModelTemplateConditionInput
) {
  deleteTemplate(input: $input, condition: $condition) {
    id
    name
    description
    tiles
    sequence
    createdAt
    aiEnabled
    updatedAt
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTemplateMutationVariables,
  APITypes.DeleteTemplateMutation
>;
export const createTask = /* GraphQL */ `mutation CreateTask(
  $input: CreateTaskInput!
  $condition: ModelTaskConditionInput
) {
  createTask(input: $input, condition: $condition) {
    id
    title
    projectId
    project {
      id
      name
      lowercaseName
      teamId
      readiness
      createdAt
      updatedAt
      buckets
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    experiment
    sequence
    teamId
    bucket
    progress
    priority
    startDate
    dueDate
    notes
    checkList
    attachments
    comments
    completed
    assigned
    updatedBy
    createdBy
    createdAt
    updatedAt
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTaskMutationVariables,
  APITypes.CreateTaskMutation
>;
export const updateTask = /* GraphQL */ `mutation UpdateTask(
  $input: UpdateTaskInput!
  $condition: ModelTaskConditionInput
) {
  updateTask(input: $input, condition: $condition) {
    id
    title
    projectId
    project {
      id
      name
      lowercaseName
      teamId
      readiness
      createdAt
      updatedAt
      buckets
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    experiment
    sequence
    teamId
    bucket
    progress
    priority
    startDate
    dueDate
    notes
    checkList
    attachments
    comments
    completed
    assigned
    updatedBy
    createdBy
    createdAt
    updatedAt
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTaskMutationVariables,
  APITypes.UpdateTaskMutation
>;
export const deleteTask = /* GraphQL */ `mutation DeleteTask(
  $input: DeleteTaskInput!
  $condition: ModelTaskConditionInput
) {
  deleteTask(input: $input, condition: $condition) {
    id
    title
    projectId
    project {
      id
      name
      lowercaseName
      teamId
      readiness
      createdAt
      updatedAt
      buckets
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    experiment
    sequence
    teamId
    bucket
    progress
    priority
    startDate
    dueDate
    notes
    checkList
    attachments
    comments
    completed
    assigned
    updatedBy
    createdBy
    createdAt
    updatedAt
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTaskMutationVariables,
  APITypes.DeleteTaskMutation
>;
export const createTeam = /* GraphQL */ `mutation CreateTeam(
  $input: CreateTeamInput!
  $condition: ModelTeamConditionInput
) {
  createTeam(input: $input, condition: $condition) {
    id
    name
    projects {
      nextToken
      __typename
    }
    createdAt
    fileUrl
    image {
      fileKey
      fileSize
      url
      __typename
    }
    updatedAt
    owner
    teamFlags
    description
    moduleConfigurations {
      processModuleID
      processModuleName
      processModuleDescription
      enabled
      __typename
    }
    teamSize
    phoneNumber
    valueProposition
    address
    color
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTeamMutationVariables,
  APITypes.CreateTeamMutation
>;
export const updateTeam = /* GraphQL */ `mutation UpdateTeam(
  $input: UpdateTeamInput!
  $condition: ModelTeamConditionInput
) {
  updateTeam(input: $input, condition: $condition) {
    id
    name
    projects {
      nextToken
      __typename
    }
    createdAt
    fileUrl
    image {
      fileKey
      fileSize
      url
      __typename
    }
    updatedAt
    owner
    teamFlags
    description
    moduleConfigurations {
      processModuleID
      processModuleName
      processModuleDescription
      enabled
      __typename
    }
    teamSize
    phoneNumber
    valueProposition
    address
    color
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTeamMutationVariables,
  APITypes.UpdateTeamMutation
>;
export const deleteTeam = /* GraphQL */ `mutation DeleteTeam(
  $input: DeleteTeamInput!
  $condition: ModelTeamConditionInput
) {
  deleteTeam(input: $input, condition: $condition) {
    id
    name
    projects {
      nextToken
      __typename
    }
    createdAt
    fileUrl
    image {
      fileKey
      fileSize
      url
      __typename
    }
    updatedAt
    owner
    teamFlags
    description
    moduleConfigurations {
      processModuleID
      processModuleName
      processModuleDescription
      enabled
      __typename
    }
    teamSize
    phoneNumber
    valueProposition
    address
    color
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTeamMutationVariables,
  APITypes.DeleteTeamMutation
>;
export const createTeamGroup = /* GraphQL */ `mutation CreateTeamGroup(
  $input: CreateTeamGroupInput!
  $condition: ModelTeamGroupConditionInput
) {
  createTeamGroup(input: $input, condition: $condition) {
    id
    name
    teamIds
    organizationRole
    organizationId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTeamGroupMutationVariables,
  APITypes.CreateTeamGroupMutation
>;
export const updateTeamGroup = /* GraphQL */ `mutation UpdateTeamGroup(
  $input: UpdateTeamGroupInput!
  $condition: ModelTeamGroupConditionInput
) {
  updateTeamGroup(input: $input, condition: $condition) {
    id
    name
    teamIds
    organizationRole
    organizationId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTeamGroupMutationVariables,
  APITypes.UpdateTeamGroupMutation
>;
export const deleteTeamGroup = /* GraphQL */ `mutation DeleteTeamGroup(
  $input: DeleteTeamGroupInput!
  $condition: ModelTeamGroupConditionInput
) {
  deleteTeamGroup(input: $input, condition: $condition) {
    id
    name
    teamIds
    organizationRole
    organizationId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTeamGroupMutationVariables,
  APITypes.DeleteTeamGroupMutation
>;
export const createProject = /* GraphQL */ `mutation CreateProject(
  $input: CreateProjectInput!
  $condition: ModelProjectConditionInput
) {
  createProject(input: $input, condition: $condition) {
    id
    name
    lowercaseName
    teamId
    team {
      id
      name
      createdAt
      fileUrl
      updatedAt
      owner
      teamFlags
      description
      teamSize
      phoneNumber
      valueProposition
      address
      color
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    canvases {
      nextToken
      __typename
    }
    readiness
    createdAt
    updatedAt
    buckets
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProjectMutationVariables,
  APITypes.CreateProjectMutation
>;
export const updateProject = /* GraphQL */ `mutation UpdateProject(
  $input: UpdateProjectInput!
  $condition: ModelProjectConditionInput
) {
  updateProject(input: $input, condition: $condition) {
    id
    name
    lowercaseName
    teamId
    team {
      id
      name
      createdAt
      fileUrl
      updatedAt
      owner
      teamFlags
      description
      teamSize
      phoneNumber
      valueProposition
      address
      color
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    canvases {
      nextToken
      __typename
    }
    readiness
    createdAt
    updatedAt
    buckets
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProjectMutationVariables,
  APITypes.UpdateProjectMutation
>;
export const deleteProject = /* GraphQL */ `mutation DeleteProject(
  $input: DeleteProjectInput!
  $condition: ModelProjectConditionInput
) {
  deleteProject(input: $input, condition: $condition) {
    id
    name
    lowercaseName
    teamId
    team {
      id
      name
      createdAt
      fileUrl
      updatedAt
      owner
      teamFlags
      description
      teamSize
      phoneNumber
      valueProposition
      address
      color
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    canvases {
      nextToken
      __typename
    }
    readiness
    createdAt
    updatedAt
    buckets
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProjectMutationVariables,
  APITypes.DeleteProjectMutation
>;
export const createInvitedUser = /* GraphQL */ `mutation CreateInvitedUser(
  $input: CreateInvitedUserInput!
  $condition: ModelInvitedUserConditionInput
) {
  createInvitedUser(input: $input, condition: $condition) {
    id
    email
    teamId
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInvitedUserMutationVariables,
  APITypes.CreateInvitedUserMutation
>;
export const updateInvitedUser = /* GraphQL */ `mutation UpdateInvitedUser(
  $input: UpdateInvitedUserInput!
  $condition: ModelInvitedUserConditionInput
) {
  updateInvitedUser(input: $input, condition: $condition) {
    id
    email
    teamId
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInvitedUserMutationVariables,
  APITypes.UpdateInvitedUserMutation
>;
export const deleteInvitedUser = /* GraphQL */ `mutation DeleteInvitedUser(
  $input: DeleteInvitedUserInput!
  $condition: ModelInvitedUserConditionInput
) {
  deleteInvitedUser(input: $input, condition: $condition) {
    id
    email
    teamId
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInvitedUserMutationVariables,
  APITypes.DeleteInvitedUserMutation
>;
export const createMessage = /* GraphQL */ `mutation CreateMessage(
  $input: CreateMessageInput!
  $condition: ModelMessageConditionInput
) {
  createMessage(input: $input, condition: $condition) {
    id
    type
    channelId
    content
    createdBy
    createdAt
    updatedAt
    updatedBy
    owner
    members
    organizations
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMessageMutationVariables,
  APITypes.CreateMessageMutation
>;
export const updateMessage = /* GraphQL */ `mutation UpdateMessage(
  $input: UpdateMessageInput!
  $condition: ModelMessageConditionInput
) {
  updateMessage(input: $input, condition: $condition) {
    id
    type
    channelId
    content
    createdBy
    createdAt
    updatedAt
    updatedBy
    owner
    members
    organizations
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageMutationVariables,
  APITypes.UpdateMessageMutation
>;
export const deleteMessage = /* GraphQL */ `mutation DeleteMessage(
  $input: DeleteMessageInput!
  $condition: ModelMessageConditionInput
) {
  deleteMessage(input: $input, condition: $condition) {
    id
    type
    channelId
    content
    createdBy
    createdAt
    updatedAt
    updatedBy
    owner
    members
    organizations
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMessageMutationVariables,
  APITypes.DeleteMessageMutation
>;
export const createMessageChannel = /* GraphQL */ `mutation CreateMessageChannel(
  $input: CreateMessageChannelInput!
  $condition: ModelMessageChannelConditionInput
) {
  createMessageChannel(input: $input, condition: $condition) {
    id
    name
    members
    organizations
    entity
    entityType
    createdAt
    updatedAt
    messages {
      nextToken
      __typename
    }
    owner
    permissionHash
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMessageChannelMutationVariables,
  APITypes.CreateMessageChannelMutation
>;
export const updateMessageChannel = /* GraphQL */ `mutation UpdateMessageChannel(
  $input: UpdateMessageChannelInput!
  $condition: ModelMessageChannelConditionInput
) {
  updateMessageChannel(input: $input, condition: $condition) {
    id
    name
    members
    organizations
    entity
    entityType
    createdAt
    updatedAt
    messages {
      nextToken
      __typename
    }
    owner
    permissionHash
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageChannelMutationVariables,
  APITypes.UpdateMessageChannelMutation
>;
export const deleteMessageChannel = /* GraphQL */ `mutation DeleteMessageChannel(
  $input: DeleteMessageChannelInput!
  $condition: ModelMessageChannelConditionInput
) {
  deleteMessageChannel(input: $input, condition: $condition) {
    id
    name
    members
    organizations
    entity
    entityType
    createdAt
    updatedAt
    messages {
      nextToken
      __typename
    }
    owner
    permissionHash
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMessageChannelMutationVariables,
  APITypes.DeleteMessageChannelMutation
>;
export const createFile = /* GraphQL */ `mutation CreateFile(
  $input: CreateFileInput!
  $condition: ModelFileConditionInput
) {
  createFile(input: $input, condition: $condition) {
    id
    key
    name
    size
    type
    teamId
    parentFolders
    searchKey
    shareSearchKey
    relativePath
    organizationId
    treeId
    createdBy
    createdAt
    updatedAt
    updatedBy
    owner
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFileMutationVariables,
  APITypes.CreateFileMutation
>;
export const updateFile = /* GraphQL */ `mutation UpdateFile(
  $input: UpdateFileInput!
  $condition: ModelFileConditionInput
) {
  updateFile(input: $input, condition: $condition) {
    id
    key
    name
    size
    type
    teamId
    parentFolders
    searchKey
    shareSearchKey
    relativePath
    organizationId
    treeId
    createdBy
    createdAt
    updatedAt
    updatedBy
    owner
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFileMutationVariables,
  APITypes.UpdateFileMutation
>;
export const deleteFile = /* GraphQL */ `mutation DeleteFile(
  $input: DeleteFileInput!
  $condition: ModelFileConditionInput
) {
  deleteFile(input: $input, condition: $condition) {
    id
    key
    name
    size
    type
    teamId
    parentFolders
    searchKey
    shareSearchKey
    relativePath
    organizationId
    treeId
    createdBy
    createdAt
    updatedAt
    updatedBy
    owner
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFileMutationVariables,
  APITypes.DeleteFileMutation
>;
export const createOrganization = /* GraphQL */ `mutation CreateOrganization(
  $input: CreateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  createOrganization(input: $input, condition: $condition) {
    id
    name
    role
    description
    ownerRole
    iconUrl
    properties
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationMutationVariables,
  APITypes.CreateOrganizationMutation
>;
export const updateOrganization = /* GraphQL */ `mutation UpdateOrganization(
  $input: UpdateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  updateOrganization(input: $input, condition: $condition) {
    id
    name
    role
    description
    ownerRole
    iconUrl
    properties
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationMutationVariables,
  APITypes.UpdateOrganizationMutation
>;
export const deleteOrganization = /* GraphQL */ `mutation DeleteOrganization(
  $input: DeleteOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  deleteOrganization(input: $input, condition: $condition) {
    id
    name
    role
    description
    ownerRole
    iconUrl
    properties
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrganizationMutationVariables,
  APITypes.DeleteOrganizationMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    owner
    displayName
    avatar
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    owner
    displayName
    avatar
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    owner
    displayName
    avatar
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createProcessModule = /* GraphQL */ `mutation CreateProcessModule(
  $input: CreateProcessModuleInput!
  $condition: ModelProcessModuleConditionInput
) {
  createProcessModule(input: $input, condition: $condition) {
    id
    name
    description
    status
    createdAt
    updatedAt
    forms {
      nextToken
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProcessModuleMutationVariables,
  APITypes.CreateProcessModuleMutation
>;
export const updateProcessModule = /* GraphQL */ `mutation UpdateProcessModule(
  $input: UpdateProcessModuleInput!
  $condition: ModelProcessModuleConditionInput
) {
  updateProcessModule(input: $input, condition: $condition) {
    id
    name
    description
    status
    createdAt
    updatedAt
    forms {
      nextToken
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProcessModuleMutationVariables,
  APITypes.UpdateProcessModuleMutation
>;
export const deleteProcessModule = /* GraphQL */ `mutation DeleteProcessModule(
  $input: DeleteProcessModuleInput!
  $condition: ModelProcessModuleConditionInput
) {
  deleteProcessModule(input: $input, condition: $condition) {
    id
    name
    description
    status
    createdAt
    updatedAt
    forms {
      nextToken
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProcessModuleMutationVariables,
  APITypes.DeleteProcessModuleMutation
>;
export const createForm = /* GraphQL */ `mutation CreateForm(
  $input: CreateFormInput!
  $condition: ModelFormConditionInput
) {
  createForm(input: $input, condition: $condition) {
    id
    processModuleID
    name
    description
    fields
    sequence
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFormMutationVariables,
  APITypes.CreateFormMutation
>;
export const updateForm = /* GraphQL */ `mutation UpdateForm(
  $input: UpdateFormInput!
  $condition: ModelFormConditionInput
) {
  updateForm(input: $input, condition: $condition) {
    id
    processModuleID
    name
    description
    fields
    sequence
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFormMutationVariables,
  APITypes.UpdateFormMutation
>;
export const deleteForm = /* GraphQL */ `mutation DeleteForm(
  $input: DeleteFormInput!
  $condition: ModelFormConditionInput
) {
  deleteForm(input: $input, condition: $condition) {
    id
    processModuleID
    name
    description
    fields
    sequence
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFormMutationVariables,
  APITypes.DeleteFormMutation
>;
export const createField = /* GraphQL */ `mutation CreateField(
  $input: CreateFieldInput!
  $condition: ModelFieldConditionInput
) {
  createField(input: $input, condition: $condition) {
    id
    type
    label
    name
    dataSource
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFieldMutationVariables,
  APITypes.CreateFieldMutation
>;
export const updateField = /* GraphQL */ `mutation UpdateField(
  $input: UpdateFieldInput!
  $condition: ModelFieldConditionInput
) {
  updateField(input: $input, condition: $condition) {
    id
    type
    label
    name
    dataSource
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFieldMutationVariables,
  APITypes.UpdateFieldMutation
>;
export const deleteField = /* GraphQL */ `mutation DeleteField(
  $input: DeleteFieldInput!
  $condition: ModelFieldConditionInput
) {
  deleteField(input: $input, condition: $condition) {
    id
    type
    label
    name
    dataSource
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFieldMutationVariables,
  APITypes.DeleteFieldMutation
>;
export const createFormSubmission = /* GraphQL */ `mutation CreateFormSubmission(
  $input: CreateFormSubmissionInput!
  $condition: ModelFormSubmissionConditionInput
) {
  createFormSubmission(input: $input, condition: $condition) {
    id
    formID
    processModuleID
    formFields
    createdAt
    updatedAt
    teamId
    owner
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFormSubmissionMutationVariables,
  APITypes.CreateFormSubmissionMutation
>;
export const updateFormSubmission = /* GraphQL */ `mutation UpdateFormSubmission(
  $input: UpdateFormSubmissionInput!
  $condition: ModelFormSubmissionConditionInput
) {
  updateFormSubmission(input: $input, condition: $condition) {
    id
    formID
    processModuleID
    formFields
    createdAt
    updatedAt
    teamId
    owner
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFormSubmissionMutationVariables,
  APITypes.UpdateFormSubmissionMutation
>;
export const deleteFormSubmission = /* GraphQL */ `mutation DeleteFormSubmission(
  $input: DeleteFormSubmissionInput!
  $condition: ModelFormSubmissionConditionInput
) {
  deleteFormSubmission(input: $input, condition: $condition) {
    id
    formID
    processModuleID
    formFields
    createdAt
    updatedAt
    teamId
    owner
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFormSubmissionMutationVariables,
  APITypes.DeleteFormSubmissionMutation
>;
