import { ex } from "@fullcalendar/core/internal-common"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Field, Form, FormSubmission, FormsByProcessModuleQuery, ListFieldsQuery, ListFormsQuery, ProcessModule } from "src/API"
import { updateProcessModule } from "src/graphql/mutations"
import { GraphQLResult, GraphQLQuery } from '@aws-amplify/api';
import $log from 'src/log/log';


type ProcessModuleState = {
    processModules: {
        list: ProcessModule[]
    },
    forms: Form[],
    fields: Field[],
    formSubmissions: {
        [processModuleId: string]: FormSubmission[]
    }
}

const initialState: ProcessModuleState = {
    processModules: {
        list: []
    },
    forms: [],
    fields: [],
    formSubmissions: {}
}

const reducers = {
    getProcessModules: (state: ProcessModuleState, action: PayloadAction<ProcessModule[]>): void => {
        state.processModules.list = action.payload
    },

    updateProcessModule: (state: ProcessModuleState, action: PayloadAction<ProcessModule>): void => {
        const index = state.processModules.list.findIndex(d => d.id === action.payload.id)
        if (index !== -1) {
            state.processModules.list[index] = action.payload
        }
    },
    getFormsForProcessModule: (state: ProcessModuleState, action: PayloadAction<GraphQLResult<GraphQLQuery<ListFormsQuery>>>): void => {
        // console.log('setting forms', action.payload.data?.listForms?.items)
        state.forms = action.payload.data?.listForms?.items as Form[]
    },
    getFields: (state: ProcessModuleState, action: PayloadAction<GraphQLResult<GraphQLQuery<ListFieldsQuery>>>) => {
        state.fields = action.payload.data?.listFields?.items as Field[]
    },
    updateFormSubmissions: (state: ProcessModuleState, action: PayloadAction<FormSubmission[]>) => {
        // update the proces modules
        $log.debug('updateFormSubmissions', { payload: action.payload })
        const updatedSubmissions = action.payload
        // update existing form submissions
        updatedSubmissions.forEach((submission) => {
            const processModuleId = submission.processModuleID
            $log.debug('updateFormSubmissions', { processModuleId })
            if (!state.formSubmissions[processModuleId]) {
                $log.debug('creating new form submissions')
                state.formSubmissions[processModuleId] = []
            }
            const index = state.formSubmissions[processModuleId].findIndex(d => d.id === submission.id)
            $log.debug('updateFormSubmissions', { index, formSubmissions: state.formSubmissions[processModuleId] })
            if (index !== -1) {
                state.formSubmissions[processModuleId][index] = { ...submission }
            } else {
                state.formSubmissions[processModuleId].push({ ...submission })
            }
        })

    }

}

export const slice = createSlice({
    name: 'processModules',
    initialState,
    reducers: reducers
})

export const { reducer } = slice