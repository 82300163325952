import { Button, Card, CardContent, Stack, SvgIcon, Typography } from '@mui/material';
import ArrowRightIcon from '@untitled-ui/icons-react/build/esm/ArrowRight';
import PropTypes from 'prop-types';
import type { FC } from 'react';
interface WelcomeStepProps {
    onNext?: () => void;
    onBack?: () => void;
}

export const WelcomeStep: FC<WelcomeStepProps> = (props) => {
    const { onBack, onNext, ...other } = props;


    return (
        <Stack
            spacing={3}
            {...other}
        >
            <Stack spacing={2}>
                <Card
                    sx={{
                        alignItems: 'center',
                        cursor: 'pointer',
                        display: 'flex',
                        p: 2,
                        // ...(category === option.value && {
                        //     backgroundColor: 'primary.alpha12',
                        //     boxShadow: (theme) => `${theme.palette.primary.main} 0 0 0 1px`
                        // })
                    }}
                >
                    <CardContent>

                        <Stack spacing={1}>
                            <Typography variant='subtitle1'>Welcome to Planvas!</Typography>
                            <Typography
                                sx={{ wordBreak: 'break-word' }}
                            >
                                Planvas is a platform that helps you to create and share your business information with organizations and investors.
                            </Typography>

                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
            <div>
                <Button
                    endIcon={(
                        <SvgIcon>
                            <ArrowRightIcon />
                        </SvgIcon>
                    )}
                    onClick={onNext}
                    variant="contained"
                >
                    Next
                </Button>
            </div>
        </Stack>
    );
};

WelcomeStep.propTypes = {
    onBack: PropTypes.func,
    onNext: PropTypes.func
};
