import {
    Avatar,
    Dialog,
    Step,
    StepContent,
    StepIconProps,
    StepLabel,
    Stepper,
    SvgIcon,
    Typography
} from '@mui/material';
import { Stack } from '@mui/system';
import CheckIcon from '@untitled-ui/icons-react/build/esm/Check';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { TeamSubmitData } from 'src/pages/settings/team-information';
import { TeamTableDetachedValues } from '../dashboard/team/team-table-detached';
import OnboardingCreationStep from './onboarding-creation-step';
import { MembersStep } from './onboarding-members-step';
import { ShareWithOrganizationStep } from './onboarding-share-organization-step';
import { TeamStep } from './onboarding-team-step';
import { WelcomeStep } from './onboarding-welcome-step';

const StepIcon: FC<StepIconProps> = (props) => {
    const { active, completed, icon } = props;

    const highlight = active || completed;

    return (
        <Avatar
            sx={{
                height: 40,
                width: 40,
                ...(highlight && {
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                }),
            }}
            variant="rounded"
        >
            {completed ? (
                <SvgIcon>
                    <CheckIcon />
                </SvgIcon>
            ) : (
                icon
            )}
        </Avatar>
    );
};

export type CreationState = {
    teamInformation?: TeamSubmitData;
    members: TeamTableDetachedValues[];
    share: string[];
};

export const OnboardingCreateForm: FC = () => {
    const [activeStep, setActiveStep] = useState<number>(0);
    const [isComplete, setIsComplete] = useState<boolean>(false);
    const [teamState, setTeamState] = useState<CreationState>({
        teamInformation: undefined,
        members: [],
        share: [],
    });

    const handleNext = useCallback(() => {
        setActiveStep((prevState) => prevState + 1);
    }, []);

    const handleBack = useCallback(() => {
        setActiveStep((prevState) => prevState - 1);
    }, []);

    const handleComplete = useCallback(() => {
        setIsComplete(true);
    }, []);

    const steps = useMemo(
        () => [
            {
                label: 'Welcome to Planvas',
                content: (
                    <WelcomeStep
                        onNext={handleNext}
                    />
                ),
            },
            {
                label: 'The Team',
                content: (
                    <TeamStep
                        onBack={handleBack}
                        onNext={(data) => {
                            setTeamState((s) => ({ ...s, teamInformation: data }));
                            handleNext();
                        }}
                        team={teamState.teamInformation}
                    />
                ),
            },
            {
                label: 'Members',
                content: (
                    <MembersStep
                        users={teamState.members}
                        onBack={handleBack}
                        onUserChange={(users) => {
                            setTeamState((s) => ({ ...s, members: users }));
                        }}
                        onNext={handleNext}
                    />
                ),
            },
            {
                label: 'Share',
                content: (
                    <ShareWithOrganizationStep
                        onRoleChange={(roles) => {
                            setTeamState((s) => ({ ...s, share: roles }));
                        }}
                        roles={teamState.share}
                        onBack={handleBack}
                        onNext={handleComplete}
                    />
                ),
            },
        ],
        [handleBack, handleNext, handleComplete, teamState]
    );

    if (isComplete) {
        return <OnboardingCreationStep data={teamState} />;
    }

    return (
        <Dialog
            fullScreen
            open={true}
        >
            <Stack
                spacing={2}
                p={2}

                alignItems={'center'}
                justifyContent={'center'}
                alignContent={'center'}
            >
                <Typography variant="h4"
                color={'primary'}
                >Create your team</Typography>
                <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                    sx={{
                        width: '70%',
                        '& .MuiStepConnector-line': {
                            borderLeftColor: 'divider',
                            borderLeftWidth: 2,
                            ml: 1,
                        },
                    }}
                >
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel StepIconComponent={StepIcon}>
                                <Typography sx={{ ml: 2 }}
                                    variant="overline">
                                    {step.label}
                                </Typography>
                            </StepLabel>
                            <StepContent
                                sx={{
                                    borderLeftColor: 'divider',
                                    borderLeftWidth: 2,
                                    ml: '20px',
                                    py: activeStep === index ? 4 : 2,
                                }}
                            >
                                {step.content}
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </Stack>

        </Dialog>

    );
};