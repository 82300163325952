import type { Theme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/system';
import PropTypes from 'prop-types';
import type { FC, HTMLAttributes, ReactNode } from 'react';
import { useSidebar } from 'src/pages/dashboard/chat';
import type { NavColor } from 'src/types/settings';
import type { Section } from '../config';
import { MobileNav } from '../mobile-nav';
import { SideNav } from './side-nav';
import { TopNav } from './top-nav';
import { useMobileNav } from './use-mobile-nav';

const SIDE_NAV_WIDTH: number = 280;

interface VerticalLayoutRootProps extends HTMLAttributes<HTMLDivElement> {
  sidebarOpen: boolean;
}

const VerticalLayoutRoot = styled('div')<VerticalLayoutRootProps>(
  ({ theme, sidebarOpen }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: sidebarOpen ? SIDE_NAV_WIDTH : 0
    }
  })
);

const VerticalLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%'
});

interface VerticalLayoutProps {
  children?: ReactNode;
  navColor?: NavColor;
  sections?: Section[];
}

export const VerticalLayout: FC<VerticalLayoutProps> = (props) => {
  const { children, sections, navColor } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const theme = useTheme();
  const mobileNav = useMobileNav();
  const sidebar = useSidebar()
  // const VerticalLayoutRoot = getVerticalRoot(sidebar.open)
  return (
    <>
      <TopNav
        sidebarOpen={sidebar.open}
        onMobileNavOpen={() => {
          mobileNav.handleOpen()
          if (lgUp) sidebar.handleToggle()
        }} />
      {(lgUp && sidebar.open) && (
        <SideNav
          open={sidebar.open}
          onClose={sidebar.handleClose}
          color={navColor}
          sections={sections}
        />
      )}
      {(!lgUp) && (
        <MobileNav
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      <VerticalLayoutRoot
        sidebarOpen={sidebar.open}
      >
        <VerticalLayoutContainer>
          {children}
        </VerticalLayoutContainer>
      </VerticalLayoutRoot >
    </>
  );
};

VerticalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf<NavColor>(['blend-in', 'discreet', 'evident', 'uic']),
  sections: PropTypes.array
};
