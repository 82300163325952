import { fileApi } from "src/api/File/FileApi"
import logger from "src/log/log"
import { Item } from "src/types/file-manager";

export async function onDownloadClick(file: Item, teamId) {
    if (!(file && teamId)) {
        logger.error("Invalid file or teamId");
        return
    }
    const response = await fileApi.getGetSignedUrl(file.id)
    if (response.ok) {
        window.open(response.result, "_blank")
    } else {
        // do some dispatch
    }
}