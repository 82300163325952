import type { PaletteColor } from '@mui/material/styles/createPalette';
import type { ColorPreset } from './index';
import { blue, green, indigo, purple } from './colors';
import { getPrimaryCustomerColor, getSecondaryCustomerColor } from './custom/customer-colors';

export const getPrimary = (preset?: ColorPreset): PaletteColor => {
  if (preset === 'uic') return getPrimaryCustomerColor('uic');
  if (preset === 'umea') return getPrimaryCustomerColor('umea');
  switch (preset) {
    case 'blue':
      return blue;
    case 'green':
      return green;
    case 'indigo':
      return indigo;
    case 'purple':
      return purple;
    default:
      console.error('Invalid color preset, accepted values: "blue", "green", "indigo" or "purple"".');
      return blue;
  }
};

export const getSecondary = (preset?: ColorPreset): PaletteColor | undefined => {
  const p = getSecondaryCustomerColor(preset)
  if (p) return p

  switch (preset) {
    case 'blue':
      return blue;
    case 'green':
      return green;
    case 'indigo':
      return indigo;
    case 'purple':
      return purple;
    default:
      console.error('Invalid color preset, accepted values: "blue", "green", "indigo" or "purple"".');
      return blue;
  }
}