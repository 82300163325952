import { Button, Divider, Drawer, IconButton, SvgIcon, Switch, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Stack } from '@mui/system';
import Share01 from '@untitled-ui/icons-react/build/esm/Share01';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import moment from 'moment';
import { useState } from 'react';
import { TeamGroup } from 'src/API';
import { Item } from 'src/types/file-manager';

interface OrganizationShareDialogProps {
    open: boolean;
    onClose: () => void;
    batches: TeamGroup[];
    item: Item
    selectedTeams: string[]
    onSelectedTeamChange: (e, teamGroups: TeamGroup) => void
    onShareClick: () => void
}

const OrganizationShareDialog = ({ open, onClose, batches, item, onShareClick, onSelectedTeamChange, selectedTeams = [] }: OrganizationShareDialogProps) => {
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const filteredTeams = batches.filter((team) =>
        team.name.toLowerCase().includes(searchTerm)
    );

    return (
        <>
            <Drawer
                anchor="right"
                ModalProps={{
                    BackdropProps: {
                        invisible: false
                    },
                    sx: { zIndex: 1400 }
                }}
                // hideBackdrop={true}
                onClose={onClose}
                open={open}
                PaperProps={{
                    sx: {
                        maxWidth: '100%',
                        width: 400
                    }
                }}
            >
                <Box sx={{ paddingTop: 3, p: 2 }}>
                    <Stack
                        direction={'row'}
                        alignContent={'center'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        <SvgIcon>
                            <Share01></Share01>
                        </SvgIcon>
                        <Typography variant='h5'>Share items</Typography>
                        <IconButton
                            edge="end"
                            onClick={() => onClose?.()}
                        >
                            <SvgIcon>
                                <XIcon />
                            </SvgIcon>
                        </IconButton>
                    </Stack>
                </Box>
                <Box sx={{ flexGrow: 1, flex: 1, overflowY: 'auto' }}>
                    <Stack spacing={2}>
                        <Box sx={{ p: 2 }}>
                            <Stack spacing={2}>
                                <Typography variant='h6'>Share folder with batches</Typography>
                                <Typography
                                >
                                    Utilize the batch file-sharing feature to efficiently distribute documents across multiple teams simultaneously. This method streamlines the process of sharing important files, ensuring that every relevant team member receives the necessary information without delay.
                                </Typography>
                            </Stack>
                        </Box>
                        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="search-teams">Search Teams</InputLabel>
                                <OutlinedInput
                                    id="search-teams"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    label="Search Teams"
                                />
                            </FormControl>
                        </Box>
                        <Typography variant='h6'
                            sx={{ p: 2 }}>Batches</Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell width={30}></TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell># Teams</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    filteredTeams.map((team) => {
                                        const date = moment(team.createdAt).format('YYYY-MM-DD')
                                        return (
                                            <TableRow key={'table-row' + team.id}>
                                                <TableCell>
                                                    <Switch
                                                        checked={selectedTeams.includes(team.id)}
                                                        value={team.id}
                                                        onClick={(e) => onSelectedTeamChange(e, team)}
                                                    ></Switch>
                                                </TableCell>
                                                <TableCell>
                                                    <Stack>
                                                        <Typography variant='body1'>{team.name}</Typography>
                                                        <Typography color={'gray'}
                                                            variant='caption'>{date}</Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell>{team.teamIds?.length || 0}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Stack>
                </Box>
                <Divider></Divider>
                <Box sx={{
                    p: 1,
                    height: 70,
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                    <Button size='small'
                        variant='contained'
                        onClick={() => onShareClick()}
                        disabled={selectedTeams.length === 0}
                    >Share</Button>
                </Box>
            </Drawer >
        </>
    );
};

export default OrganizationShareDialog;
