import {
  Box,
  Button,
  Container,
  Divider,
  Unstable_Grid2 as Grid,
  Stack,
  SvgIcon,
  Tab,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography
} from '@mui/material';
import Globe01 from '@untitled-ui/icons-react/build/esm/Globe01';
import Trash01 from '@untitled-ui/icons-react/build/esm/Trash01';
import Upload01Icon from '@untitled-ui/icons-react/build/esm/Upload01';
import User01 from '@untitled-ui/icons-react/build/esm/User01';
import type { ChangeEvent, MouseEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { fileApi } from 'src/api/File/FileApi';
import ConfirmPopover from 'src/components/confirm-popover';
import { Seo } from 'src/components/seo';
import { useDialog } from 'src/hooks/use-dialog';
import { useMounted } from 'src/hooks/use-mounted';
import { usePageView } from 'src/hooks/use-page-view';
import { useSettings } from 'src/hooks/use-settings';
import $log from 'src/log/log';
import FileManagerBreadcrumb from 'src/sections/dashboard/file-manager/file-manager-breadcrumb';
import { FileUploader } from 'src/sections/dashboard/file-manager/file-uploader';
import { ItemIcon } from 'src/sections/dashboard/file-manager/item-icon';
import { ItemList } from 'src/sections/dashboard/file-manager/item-list';
import { ItemSearch } from 'src/sections/dashboard/file-manager/item-search';
import ItemShareDrawer from 'src/sections/dashboard/file-manager/item-share-drawer';
import type { Item } from 'src/types/file-manager';
import type { Page as PageType } from 'src/types/page';
import { bytesToSize } from 'src/utils/bytes-to-size';
import { useGetUsers } from '../../hooks/use-get-users-in-team';
import { getUserRecords } from '../../sections/dashboard/team/team-table';

type View = 'grid' | 'list';

interface Filters {
  query?: string;
}

type SortDir = 'asc' | 'desc';



export const useItemsSearch = () => {
  const [state, setState] = useState<ItemsSearchState>({
    filters: {
      query: undefined
    },
    page: 0,
    rowsPerPage: 9,
    sortBy: 'created',
    sortDir: 'desc'
  });

  const handleFiltersChange = useCallback(
    (filters: Filters): void => {
      setState((prevState) => ({
        ...prevState,
        filters
      }));
    },
    []
  );

  const handleSortChange = useCallback(
    (sortDir: SortDir): void => {
      setState((prevState) => ({
        ...prevState,
        sortDir
      }));
    },
    []
  );

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number): void => {
      setState((prevState) => ({
        ...prevState,
        page
      }));
    },
    []
  );

  const handleRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setState((prevState) => ({
        ...prevState,
        rowsPerPage: parseInt(event.target.value, 10)
      }));
    },
    []
  );

  return {
    handleFiltersChange,
    handleSortChange,
    handlePageChange,
    handleRowsPerPageChange,
    state
  };
};


interface ItemsSearchState {
  filters: Filters;
  page: number;
  rowsPerPage: number;
  sortBy?: string;
  sortDir?: SortDir;
}

interface ItemsStoreState {
  items: Item[],
  itemsCount: number;
}

const useItemsStore = (tab: TabValue, itemsSearch: ItemsSearchState) => {
  const isMounted = useMounted();
  const { teamId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams();
  let path = searchParams.get('path')
  const [state, setState] = useState<ItemsStoreState>({
    items: [],
    itemsCount: 0
  });

  const handleItemsGet = useCallback(
    async () => {
      try {
        let response
        if (!teamId) return
        if (!path) path = '/'
        if (tab === 'myFiles') {
          response = await fileApi.getFilesForPath(teamId, path)
        } else if (tab === 'sharedFiles') {
          response = await fileApi.getSharedFilesForPath(teamId, path)
        }
        if (isMounted()) {
          setState({
            items: response.result.items,
            itemsCount: response.result.length
          });
        }
      } catch (err) {
        console.error(err);
        toast.error("Failed to get items");
      }
    },
    [path, isMounted, teamId, tab]
  );

  useEffect(() => {
    setState(s => ({
      ...s,
      items: (state.items || []).sort((a, b) => {
        const sortBy = itemsSearch.sortBy || 'created';
        const A = new Date(a[sortBy]).getTime()
        const B = new Date(b[sortBy]).getTime()
        if (itemsSearch.sortDir === 'desc') {
          return B - A
        } else {
          return A - B
        }
      })
    }))

  }, [state.items, itemsSearch.sortBy, itemsSearch.sortDir])



  useEffect(
    () => {
      handleItemsGet();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [path, tab]
  );

  const handleDelete = useCallback(
    (): void => {
    },
    []
  );

  const handleFavorite = useCallback(
    (itemId: string, value: boolean): void => {
      setState((prevState) => {
        return {
          ...prevState,
          items: prevState.items.map((item) => {
            if (item.id === itemId) {
              return {
                ...item,
                isFavorite: value
              };
            }

            return item;
          })
        };
      });
    },
    []
  );

  return {
    handleDelete,
    handleFavorite,
    ...state
  };
};

const useCurrentItem = (items: Item[], itemId?: string): Item | undefined => {
  return useMemo(
    (): Item | undefined => {
      if (!itemId) {
        return undefined;
      }

      return items.find((item) => item.id === itemId);
    },
    [items, itemId]
  );
};

type TabValue = "myFiles" | "sharedFiles" | 'organization'

const rootPath = '/'

const Page: PageType = () => {
  const settings = useSettings();
  const itemsSearch = useItemsSearch();
  const [tab, setTab] = useState<TabValue>('myFiles')
  const itemsStore = useItemsStore(tab, itemsSearch.state);
  const [view, setView] = useState<View>('grid');
  const uploadDialog = useDialog();
  const detailsDialog = useDialog<string>();
  const confirmDialog = useDialog<string>();
  const teamUsers = useGetUsers()
  const users = getUserRecords(teamUsers)
  const [currentItem, setCurrentItem] = useState<Item | null>(null)
  const navigate = useNavigate()
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams()
  const path = searchParams.get('path')

  const { teamId } = useParams()

  const handleItemClick = useCallback((item: Item) => {
    if (item.__typename === 'Folder') {
      navigate(`?path=${item.relativePath}&id=${item.id}`)
    }
  }, [navigate])

  usePageView();

  const handleUpload = useCallback((formData: FormData) => {
    if (!teamId) {
      $log.error("No teamId")
      return
    }
    return fileApi.uploadfiles(formData, {
      teamId,
      path: searchParams.get('id') || '/'
    }, (event) => {
      // onUploadProgress && onUploadProgress(event.loaded / event.total * 100)
    })
  }, [searchParams, teamId])

  const handleDelete = useCallback(
    async (): Promise<void> => {
      if (!teamId) return
      const result = await fileApi.deleteFile(checkedItems, teamId)
      if (!result.ok) {
        toast.error("Failure when deleting items");
      } else {
        toast.success("Successfully deleted items");
      }
    },
    [teamId, checkedItems]
  );

  const handleOnShare = useCallback((item: Item) => {
    setCurrentItem(item)
    detailsDialog.handleOpen(item.id);
  }, [detailsDialog])

  const handleCheckedItem = useCallback((item: Item) => {
    setCheckedItems((prevState) => {
      if (!prevState.includes(item.id)) {
        return [...new Set([...prevState, item.id])]
      } else {
        return [...prevState.filter((id) => id !== item.id)]
      }
    })
  }, [])


  useEffect(() => {
    // if view or navigate then reset
    setCheckedItems([])
    return () => { }
  }, [view, path])

  const handleTabChange = useCallback((tab: TabValue) => {
    setTab(tab)
  }, [])


  const filteredItems = (itemsStore.items || [])
    .filter(f => {
      if (!itemsSearch.state.filters!.query!) return true
      if (itemsSearch.state.filters!.query!.trim() === '') return true

      return f.name.toLowerCase().includes(itemsSearch.state.filters!.query!.toLowerCase())
    })


  return (
    <>
      <Seo title="File Manager" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth={settings.stretch ? false : 'xl'}>
          <Grid
            container
            spacing={{
              xs: 3,
              lg: 4
            }}
          >
            <Grid xs={12}>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={4}
              >
                <div>
                  <Typography variant="h4">
                    File Manager
                  </Typography>
                </div>
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={2}
                >
                  <Button
                    onClick={uploadDialog.handleOpen}
                    startIcon={(
                      <SvgIcon>
                        <Upload01Icon />
                      </SvgIcon>
                    )}
                    variant="contained"
                  >
                    Upload
                  </Button>
                </Stack>
              </Stack>
            </Grid>
            <Grid
              xs={12}
              md={12}
            >
              <Stack
                spacing={{
                  xs: 3,
                  lg: 4
                }}
              >
                <Tabs
                  indicatorColor="primary"
                  onChange={(e, value) => {
                    handleTabChange(value)
                  }}
                  scrollButtons="auto"
                  textColor="primary"
                  value={tab}
                  variant="scrollable"
                >
                  <Tab
                    icon={<User01></User01>}
                    iconPosition='start'
                    label="My Files"
                    value="myFiles"
                  ></Tab>
                  <Tab
                    icon={<Globe01></Globe01>}
                    iconPosition='start'
                    label="Shared files"
                    value="sharedFiles"
                  ></Tab>
                </Tabs>

                <ItemSearch
                  onFiltersChange={itemsSearch.handleFiltersChange}
                  onSortChange={itemsSearch.handleSortChange}
                  onViewChange={setView}
                  sortBy={itemsSearch.state.sortBy}
                  sortDir={itemsSearch.state.sortDir}
                  view={view}
                />
                <FileManagerBreadcrumb></FileManagerBreadcrumb>
                <Stack direction={'row'}>
                  <Box>
                    <ConfirmPopover
                      title=''
                      // onClose={confirmDialog.handleClose}
                      onConfirm={handleDelete}
                      content={<>
                        <Typography>Are you sure you want to delete these items?</Typography>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Type</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell>Size</TableCell>
                            </TableRow>
                          </TableHead>
                          {checkedItems.map(id => {
                            const item = itemsStore.items.find((item) => item.id === id)
                            if (!item) return null
                            const size = item.size && bytesToSize(item.size);

                            return (<TableRow>
                              <TableCell>
                                <ItemIcon
                                  type={item.__typename}
                                  extension={item.name.split('.').pop()}
                                ></ItemIcon>
                              </TableCell>
                              <TableCell>
                                {item.name}
                              </TableCell>
                              <TableCell>
                                {size || ''}
                              </TableCell>
                            </TableRow>)
                          })}
                        </Table>
                      </>}
                      component={(handleClick) => (<Button
                        disabled={checkedItems.length === 0}
                        startIcon={<Trash01 />}
                        color='error'
                        onClick={e => handleClick()}>
                        Delete
                      </Button>)}
                      onCancel={confirmDialog.handleClose}
                      onButtonClick={handleDelete}
                    ></ConfirmPopover>

                  </Box>
                </Stack>
                <Divider sx={{ marginTop: 5 }} />
                <ItemList
                  onShare={handleOnShare}
                  users={users}
                  count={itemsStore.itemsCount}
                  items={filteredItems}
                  onDelete={handleDelete}
                  onFavorite={itemsStore.handleFavorite}
                  onOpen={handleItemClick}
                  onPageChange={itemsSearch.handlePageChange}
                  onRowsPerPageChange={itemsSearch.handleRowsPerPageChange}
                  page={itemsSearch.state.page}
                  onItemChecked={handleCheckedItem}
                  rowsPerPage={itemsSearch.state.rowsPerPage}
                  view={view}
                />
              </Stack>
            </Grid>
            {/* <Grid
              xs={12}
              md={4}
            >
              <StorageStats />
            </Grid> */}
          </Grid>
        </Container>
      </Box>
      {/* <ItemDrawer
        item={currentItem}
        onClose={detailsDialog.handleClose}
        onDelete={handleDelete}
        onFavorite={itemsStore.handleFavorite}
        open={detailsDialog.open}
      /> */}
      {currentItem && (<ItemShareDrawer
        open={detailsDialog.open}
        users={users}
        onClose={detailsDialog.handleClose}
        item={currentItem}
      ></ItemShareDrawer>)}

      <FileUploader
        onUpload={handleUpload}
        onClose={uploadDialog.handleClose}
        open={uploadDialog.open}
      />
    </>
  );
};

export default Page;
