/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getCanvas = /* GraphQL */ `query GetCanvas($id: ID!) {
  getCanvas(id: $id) {
    id
    name
    lowercaseName
    teamId
    canvasTiles {
      nextToken
      __typename
    }
    projectId
    project {
      id
      name
      lowercaseName
      teamId
      readiness
      createdAt
      updatedAt
      buckets
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    createdAt
    updatedAt
    owner
    channelId
    properties
    ownerEmail
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCanvasQueryVariables, APITypes.GetCanvasQuery>;
export const listCanvass = /* GraphQL */ `query ListCanvass(
  $filter: ModelCanvasFilterInput
  $limit: Int
  $nextToken: String
) {
  listCanvass(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      lowercaseName
      teamId
      projectId
      createdAt
      updatedAt
      owner
      channelId
      properties
      ownerEmail
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCanvassQueryVariables,
  APITypes.ListCanvassQuery
>;
export const getCanvasTile = /* GraphQL */ `query GetCanvasTile($id: ID!) {
  getCanvasTile(id: $id) {
    id
    canvasId
    tasks
    teamId
    message
    position
    title
    createdAt
    updatedAt
    updatedBy
    info
    owner
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCanvasTileQueryVariables,
  APITypes.GetCanvasTileQuery
>;
export const listCanvasTiles = /* GraphQL */ `query ListCanvasTiles(
  $filter: ModelCanvasTileFilterInput
  $limit: Int
  $nextToken: String
) {
  listCanvasTiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      canvasId
      tasks
      teamId
      message
      position
      title
      createdAt
      updatedAt
      updatedBy
      info
      owner
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCanvasTilesQueryVariables,
  APITypes.ListCanvasTilesQuery
>;
export const getTemplate = /* GraphQL */ `query GetTemplate($id: ID!) {
  getTemplate(id: $id) {
    id
    name
    description
    tiles
    sequence
    createdAt
    aiEnabled
    updatedAt
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTemplateQueryVariables,
  APITypes.GetTemplateQuery
>;
export const listTemplates = /* GraphQL */ `query ListTemplates(
  $filter: ModelTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  listTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      tiles
      sequence
      createdAt
      aiEnabled
      updatedAt
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTemplatesQueryVariables,
  APITypes.ListTemplatesQuery
>;
export const getTask = /* GraphQL */ `query GetTask($id: ID!) {
  getTask(id: $id) {
    id
    title
    projectId
    project {
      id
      name
      lowercaseName
      teamId
      readiness
      createdAt
      updatedAt
      buckets
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    experiment
    sequence
    teamId
    bucket
    progress
    priority
    startDate
    dueDate
    notes
    checkList
    attachments
    comments
    completed
    assigned
    updatedBy
    createdBy
    createdAt
    updatedAt
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTaskQueryVariables, APITypes.GetTaskQuery>;
export const listTasks = /* GraphQL */ `query ListTasks(
  $filter: ModelTaskFilterInput
  $limit: Int
  $nextToken: String
) {
  listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      projectId
      experiment
      sequence
      teamId
      bucket
      progress
      priority
      startDate
      dueDate
      notes
      checkList
      attachments
      comments
      completed
      assigned
      updatedBy
      createdBy
      createdAt
      updatedAt
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTasksQueryVariables, APITypes.ListTasksQuery>;
export const getTeam = /* GraphQL */ `query GetTeam($id: ID!) {
  getTeam(id: $id) {
    id
    name
    projects {
      nextToken
      __typename
    }
    createdAt
    fileUrl
    image {
      fileKey
      fileSize
      url
      __typename
    }
    updatedAt
    owner
    teamFlags
    description
    moduleConfigurations {
      processModuleID
      processModuleName
      processModuleDescription
      enabled
      __typename
    }
    teamSize
    phoneNumber
    valueProposition
    address
    color
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTeamQueryVariables, APITypes.GetTeamQuery>;
export const listTeams = /* GraphQL */ `query ListTeams(
  $filter: ModelTeamFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      fileUrl
      updatedAt
      owner
      teamFlags
      description
      teamSize
      phoneNumber
      valueProposition
      address
      color
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTeamsQueryVariables, APITypes.ListTeamsQuery>;
export const getTeamGroup = /* GraphQL */ `query GetTeamGroup($id: ID!) {
  getTeamGroup(id: $id) {
    id
    name
    teamIds
    organizationRole
    organizationId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTeamGroupQueryVariables,
  APITypes.GetTeamGroupQuery
>;
export const listTeamGroups = /* GraphQL */ `query ListTeamGroups(
  $filter: ModelTeamGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeamGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      teamIds
      organizationRole
      organizationId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeamGroupsQueryVariables,
  APITypes.ListTeamGroupsQuery
>;
export const getProject = /* GraphQL */ `query GetProject($id: ID!) {
  getProject(id: $id) {
    id
    name
    lowercaseName
    teamId
    team {
      id
      name
      createdAt
      fileUrl
      updatedAt
      owner
      teamFlags
      description
      teamSize
      phoneNumber
      valueProposition
      address
      color
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    canvases {
      nextToken
      __typename
    }
    readiness
    createdAt
    updatedAt
    buckets
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectQueryVariables,
  APITypes.GetProjectQuery
>;
export const listProjects = /* GraphQL */ `query ListProjects(
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      lowercaseName
      teamId
      readiness
      createdAt
      updatedAt
      buckets
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectsQueryVariables,
  APITypes.ListProjectsQuery
>;
export const getInvitedUser = /* GraphQL */ `query GetInvitedUser($id: ID!) {
  getInvitedUser(id: $id) {
    id
    email
    teamId
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvitedUserQueryVariables,
  APITypes.GetInvitedUserQuery
>;
export const listInvitedUsers = /* GraphQL */ `query ListInvitedUsers(
  $filter: ModelInvitedUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvitedUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      teamId
      type
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvitedUsersQueryVariables,
  APITypes.ListInvitedUsersQuery
>;
export const getMessage = /* GraphQL */ `query GetMessage($id: ID!) {
  getMessage(id: $id) {
    id
    type
    channelId
    content
    createdBy
    createdAt
    updatedAt
    updatedBy
    owner
    members
    organizations
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMessageQueryVariables,
  APITypes.GetMessageQuery
>;
export const listMessages = /* GraphQL */ `query ListMessages(
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      channelId
      content
      createdBy
      createdAt
      updatedAt
      updatedBy
      owner
      members
      organizations
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMessagesQueryVariables,
  APITypes.ListMessagesQuery
>;
export const getMessageChannel = /* GraphQL */ `query GetMessageChannel($id: ID!) {
  getMessageChannel(id: $id) {
    id
    name
    members
    organizations
    entity
    entityType
    createdAt
    updatedAt
    messages {
      nextToken
      __typename
    }
    owner
    permissionHash
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMessageChannelQueryVariables,
  APITypes.GetMessageChannelQuery
>;
export const listMessageChannels = /* GraphQL */ `query ListMessageChannels(
  $filter: ModelMessageChannelFilterInput
  $limit: Int
  $nextToken: String
) {
  listMessageChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      members
      organizations
      entity
      entityType
      createdAt
      updatedAt
      owner
      permissionHash
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMessageChannelsQueryVariables,
  APITypes.ListMessageChannelsQuery
>;
export const getFile = /* GraphQL */ `query GetFile($id: ID!) {
  getFile(id: $id) {
    id
    key
    name
    size
    type
    teamId
    parentFolders
    searchKey
    shareSearchKey
    relativePath
    organizationId
    treeId
    createdBy
    createdAt
    updatedAt
    updatedBy
    owner
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFileQueryVariables, APITypes.GetFileQuery>;
export const listFiles = /* GraphQL */ `query ListFiles(
  $filter: ModelFileFilterInput
  $limit: Int
  $nextToken: String
) {
  listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      key
      name
      size
      type
      teamId
      parentFolders
      searchKey
      shareSearchKey
      relativePath
      organizationId
      treeId
      createdBy
      createdAt
      updatedAt
      updatedBy
      owner
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListFilesQueryVariables, APITypes.ListFilesQuery>;
export const getOrganization = /* GraphQL */ `query GetOrganization($id: ID!) {
  getOrganization(id: $id) {
    id
    name
    role
    description
    ownerRole
    iconUrl
    properties
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationQueryVariables,
  APITypes.GetOrganizationQuery
>;
export const listOrganizations = /* GraphQL */ `query ListOrganizations(
  $filter: ModelOrganizationFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      role
      description
      ownerRole
      iconUrl
      properties
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationsQueryVariables,
  APITypes.ListOrganizationsQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    owner
    displayName
    avatar
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      displayName
      avatar
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getProcessModule = /* GraphQL */ `query GetProcessModule($id: ID!) {
  getProcessModule(id: $id) {
    id
    name
    description
    status
    createdAt
    updatedAt
    forms {
      nextToken
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProcessModuleQueryVariables,
  APITypes.GetProcessModuleQuery
>;
export const listProcessModules = /* GraphQL */ `query ListProcessModules(
  $filter: ModelProcessModuleFilterInput
  $limit: Int
  $nextToken: String
) {
  listProcessModules(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProcessModulesQueryVariables,
  APITypes.ListProcessModulesQuery
>;
export const getForm = /* GraphQL */ `query GetForm($id: ID!) {
  getForm(id: $id) {
    id
    processModuleID
    name
    description
    fields
    sequence
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFormQueryVariables, APITypes.GetFormQuery>;
export const listForms = /* GraphQL */ `query ListForms(
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  listForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      processModuleID
      name
      description
      fields
      sequence
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListFormsQueryVariables, APITypes.ListFormsQuery>;
export const getField = /* GraphQL */ `query GetField($id: ID!) {
  getField(id: $id) {
    id
    type
    label
    name
    dataSource
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFieldQueryVariables, APITypes.GetFieldQuery>;
export const listFields = /* GraphQL */ `query ListFields(
  $filter: ModelFieldFilterInput
  $limit: Int
  $nextToken: String
) {
  listFields(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      label
      name
      dataSource
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFieldsQueryVariables,
  APITypes.ListFieldsQuery
>;
export const getFormSubmission = /* GraphQL */ `query GetFormSubmission($id: ID!) {
  getFormSubmission(id: $id) {
    id
    formID
    processModuleID
    formFields
    createdAt
    updatedAt
    teamId
    owner
    adminGroups
    editorGroups
    viewerGroups
    managerGroups
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFormSubmissionQueryVariables,
  APITypes.GetFormSubmissionQuery
>;
export const listFormSubmissions = /* GraphQL */ `query ListFormSubmissions(
  $filter: ModelFormSubmissionFilterInput
  $limit: Int
  $nextToken: String
) {
  listFormSubmissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      formID
      processModuleID
      formFields
      createdAt
      updatedAt
      teamId
      owner
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFormSubmissionsQueryVariables,
  APITypes.ListFormSubmissionsQuery
>;
export const canvasByProject = /* GraphQL */ `query CanvasByProject(
  $projectId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelCanvasFilterInput
  $limit: Int
  $nextToken: String
) {
  canvasByProject(
    projectId: $projectId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      lowercaseName
      teamId
      projectId
      createdAt
      updatedAt
      owner
      channelId
      properties
      ownerEmail
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CanvasByProjectQueryVariables,
  APITypes.CanvasByProjectQuery
>;
export const canvasTilesByCanvas = /* GraphQL */ `query CanvasTilesByCanvas(
  $canvasId: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCanvasTileFilterInput
  $limit: Int
  $nextToken: String
) {
  canvasTilesByCanvas(
    canvasId: $canvasId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      canvasId
      tasks
      teamId
      message
      position
      title
      createdAt
      updatedAt
      updatedBy
      info
      owner
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CanvasTilesByCanvasQueryVariables,
  APITypes.CanvasTilesByCanvasQuery
>;
export const canvasTilesByTeam = /* GraphQL */ `query CanvasTilesByTeam(
  $teamId: String
  $sortDirection: ModelSortDirection
  $filter: ModelCanvasTileFilterInput
  $limit: Int
  $nextToken: String
) {
  canvasTilesByTeam(
    teamId: $teamId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      canvasId
      tasks
      teamId
      message
      position
      title
      createdAt
      updatedAt
      updatedBy
      info
      owner
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CanvasTilesByTeamQueryVariables,
  APITypes.CanvasTilesByTeamQuery
>;
export const taskByProjectAndUpdatedAt = /* GraphQL */ `query TaskByProjectAndUpdatedAt(
  $projectId: ID
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTaskFilterInput
  $limit: Int
  $nextToken: String
) {
  taskByProjectAndUpdatedAt(
    projectId: $projectId
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      projectId
      experiment
      sequence
      teamId
      bucket
      progress
      priority
      startDate
      dueDate
      notes
      checkList
      attachments
      comments
      completed
      assigned
      updatedBy
      createdBy
      createdAt
      updatedAt
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TaskByProjectAndUpdatedAtQueryVariables,
  APITypes.TaskByProjectAndUpdatedAtQuery
>;
export const taskByProject = /* GraphQL */ `query TaskByProject(
  $projectId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelTaskFilterInput
  $limit: Int
  $nextToken: String
) {
  taskByProject(
    projectId: $projectId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      projectId
      experiment
      sequence
      teamId
      bucket
      progress
      priority
      startDate
      dueDate
      notes
      checkList
      attachments
      comments
      completed
      assigned
      updatedBy
      createdBy
      createdAt
      updatedAt
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TaskByProjectQueryVariables,
  APITypes.TaskByProjectQuery
>;
export const taskByTeam = /* GraphQL */ `query TaskByTeam(
  $teamId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelTaskFilterInput
  $limit: Int
  $nextToken: String
) {
  taskByTeam(
    teamId: $teamId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      projectId
      experiment
      sequence
      teamId
      bucket
      progress
      priority
      startDate
      dueDate
      notes
      checkList
      attachments
      comments
      completed
      assigned
      updatedBy
      createdBy
      createdAt
      updatedAt
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TaskByTeamQueryVariables,
  APITypes.TaskByTeamQuery
>;
export const teamGroupByOrganization = /* GraphQL */ `query TeamGroupByOrganization(
  $organizationId: String
  $sortDirection: ModelSortDirection
  $filter: ModelTeamGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  teamGroupByOrganization(
    organizationId: $organizationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      teamIds
      organizationRole
      organizationId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TeamGroupByOrganizationQueryVariables,
  APITypes.TeamGroupByOrganizationQuery
>;
export const projectByTeam = /* GraphQL */ `query ProjectByTeam(
  $teamId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  projectByTeam(
    teamId: $teamId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      lowercaseName
      teamId
      readiness
      createdAt
      updatedAt
      buckets
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProjectByTeamQueryVariables,
  APITypes.ProjectByTeamQuery
>;
export const invitedUserByTeam = /* GraphQL */ `query InvitedUserByTeam(
  $teamId: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvitedUserFilterInput
  $limit: Int
  $nextToken: String
) {
  invitedUserByTeam(
    teamId: $teamId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      email
      teamId
      type
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvitedUserByTeamQueryVariables,
  APITypes.InvitedUserByTeamQuery
>;
export const messageByChannel = /* GraphQL */ `query MessageByChannel(
  $channelId: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  messageByChannel(
    channelId: $channelId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      channelId
      content
      createdBy
      createdAt
      updatedAt
      updatedBy
      owner
      members
      organizations
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MessageByChannelQueryVariables,
  APITypes.MessageByChannelQuery
>;
export const byPermissionHash = /* GraphQL */ `query ByPermissionHash(
  $permissionHash: String
  $sortDirection: ModelSortDirection
  $filter: ModelMessageChannelFilterInput
  $limit: Int
  $nextToken: String
) {
  byPermissionHash(
    permissionHash: $permissionHash
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      members
      organizations
      entity
      entityType
      createdAt
      updatedAt
      owner
      permissionHash
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ByPermissionHashQueryVariables,
  APITypes.ByPermissionHashQuery
>;
export const filesByTeam = /* GraphQL */ `query FilesByTeam(
  $teamId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelFileFilterInput
  $limit: Int
  $nextToken: String
) {
  filesByTeam(
    teamId: $teamId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      key
      name
      size
      type
      teamId
      parentFolders
      searchKey
      shareSearchKey
      relativePath
      organizationId
      treeId
      createdBy
      createdAt
      updatedAt
      updatedBy
      owner
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FilesByTeamQueryVariables,
  APITypes.FilesByTeamQuery
>;
export const filesBySearchKey = /* GraphQL */ `query FilesBySearchKey(
  $teamId: ID
  $searchKey: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFileFilterInput
  $limit: Int
  $nextToken: String
) {
  filesBySearchKey(
    teamId: $teamId
    searchKey: $searchKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      key
      name
      size
      type
      teamId
      parentFolders
      searchKey
      shareSearchKey
      relativePath
      organizationId
      treeId
      createdBy
      createdAt
      updatedAt
      updatedBy
      owner
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FilesBySearchKeyQueryVariables,
  APITypes.FilesBySearchKeyQuery
>;
export const OrgFilesBySearchKey = /* GraphQL */ `query OrgFilesBySearchKey(
  $organizationId: String
  $searchKey: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFileFilterInput
  $limit: Int
  $nextToken: String
) {
  OrgFilesBySearchKey(
    organizationId: $organizationId
    searchKey: $searchKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      key
      name
      size
      type
      teamId
      parentFolders
      searchKey
      shareSearchKey
      relativePath
      organizationId
      treeId
      createdBy
      createdAt
      updatedAt
      updatedBy
      owner
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OrgFilesBySearchKeyQueryVariables,
  APITypes.OrgFilesBySearchKeyQuery
>;
export const fileByOwnerAndTreeId = /* GraphQL */ `query FileByOwnerAndTreeId(
  $owner: String
  $treeId: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFileFilterInput
  $limit: Int
  $nextToken: String
) {
  fileByOwnerAndTreeId(
    owner: $owner
    treeId: $treeId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      key
      name
      size
      type
      teamId
      parentFolders
      searchKey
      shareSearchKey
      relativePath
      organizationId
      treeId
      createdBy
      createdAt
      updatedAt
      updatedBy
      owner
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FileByOwnerAndTreeIdQueryVariables,
  APITypes.FileByOwnerAndTreeIdQuery
>;
export const formsByProcessModule = /* GraphQL */ `query FormsByProcessModule(
  $processModuleID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByProcessModule(
    processModuleID: $processModuleID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      processModuleID
      name
      description
      fields
      sequence
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FormsByProcessModuleQueryVariables,
  APITypes.FormsByProcessModuleQuery
>;
export const formSubmissionsByProcessModule = /* GraphQL */ `query FormSubmissionsByProcessModule(
  $processModuleID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelFormSubmissionFilterInput
  $limit: Int
  $nextToken: String
) {
  formSubmissionsByProcessModule(
    processModuleID: $processModuleID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      formID
      processModuleID
      formFields
      createdAt
      updatedAt
      teamId
      owner
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FormSubmissionsByProcessModuleQueryVariables,
  APITypes.FormSubmissionsByProcessModuleQuery
>;
