
import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { TeamAccountGuard } from 'src/guards/team-account-guard';
import { Layout as DashboardLayout } from 'src/layouts/dashboard';
import Canvas from 'src/pages/canvas';
import ChatPage from 'src/pages/dashboard/chat';
import { OnboardingCreateForm } from 'src/sections/onboarding/onboarding-create-form';
const StepModule = lazy(() => import('src/pages/modules/SRM'));
const CreateProject = lazy(() => import('src/pages/project/create'));
const CanvasOverview = lazy(() => import('src/pages/project/overview'));
// const TeamPage = lazy(() => import('src/pages/team/index'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
const IndexPage = lazy(() => import('src/pages/dashboard/index'));
const InnovationReadiness = lazy(() => import('src/pages/track/innovation-readiness'));
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
const TeamSetting = lazy(() => import('src/pages/settings/team'));
const CreateCanvas = lazy(() => import('src/pages/canvas/create-canvas'));
const CreateAiCanvas = lazy(() => import('src/pages/canvas/create-ai-canvas/create-ai-canvas-page'));
const InfiniteCanvas = lazy(() => import('src/pages/infinite-canvas'));

export const teamRoutes: RouteObject[] = [
    {
        path: "team",
        children: [
            {
                path: "create",
                element: <DashboardLayout><OnboardingCreateForm /></DashboardLayout>,
            },
        ],
    },
    {
        path: "/t/",
        element: (<DashboardLayout>
            <TeamAccountGuard>
                <Suspense fallback={(<div>Loading</div>)}>
                    <Outlet />
                </Suspense>
            </TeamAccountGuard>
        </DashboardLayout>),
        children: [
            {
                path: "new",
                element: <OnboardingCreateForm />
            },
            {
                path: ":teamId",
                children: [
                    {
                        path: 'module/:moduleId',
                        children: [
                            {
                                index: true,
                                element: <StepModule />
                            },

                            // {
                            //     element: <SRMModule />,
                            //     handle: (params: any) => console.log("handle", params),
                            // }
                        ]
                    },
                    {
                        index: true,
                        element: <IndexPage />
                    },
                    {
                        path: "files",
                        element: < FileManagerPage />
                    },
                    {
                        path: "p/new",
                        element: <CreateProject />
                    },
                    {
                        path: 'chat',
                        element: <ChatPage />,
                    },
                    {
                        path: 'settings',
                        element: <TeamSetting />,
                    },
                    {
                        path: "p/:projectId/",
                        children: [
                            {
                                index: true,
                                element: <CanvasOverview />
                            },
                            {
                                path: 'canvases',
                                element: <CanvasOverview />
                            },
                            {
                                path: 'canvas/create',
                                element: <CreateCanvas />
                            },
                            {
                                path: 'canvas/create-ai-canvas',
                                element: <CreateAiCanvas />
                            },
                            {
                                path: "c/:canvasId",
                                element: <Canvas></Canvas>,
                            },
                            {
                                path: "kanban",
                                element: <KanbanPage />,
                            },
                            {
                                path: "innovation-readiness",
                                element: <InnovationReadiness />,
                            },
                            {
                                path: "infinite-canvas",
                                element: <InfiniteCanvas></InfiniteCanvas>,
                            }
                        ]
                    },
                ]
            },


        ]
    }
]