import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, IconButton, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import Trash02Icon from '@untitled-ui/icons-react/build/esm/Trash02';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { TeamGroup } from 'src/API';
import ConfirmPopover from 'src/components/confirm-popover';
import { useDialog } from 'src/hooks/use-dialog';
import { useGetTeams } from 'src/hooks/use-get-teams';
import { useTeamBatches } from 'src/hooks/use-team-batches';
import { useOrganization } from 'src/pages/settings/organization';
import { useDispatch } from 'src/store';
import { thunks } from 'src/thunks/team-group';
import OrganizationBatchesEditModal from './organization-batches-edit-modal';
import OrganizationSelectTeam from './organization-select-team';





export default function OrganizationBatches() {
    const teamsResult = useGetTeams()
    const [selectedTeams, setSelectedTeams] = useState([])
    const [batchName, setBatchName] = useState('')
    const organization = useOrganization()
    const [editTeamGroup, setEditTeamGroup] = useState<TeamGroup | undefined>(undefined)
    const dispatch = useDispatch()
    const batches = useTeamBatches()
    const dialog = useDialog()
    const editDialog = useDialog<TeamGroup>()

    const resetValues = useCallback(() => {
        setSelectedTeams([])
        setBatchName('')
    }, [])


    const handleEditTeamGroupClick = useCallback((teamGroup) => {
        setEditTeamGroup(teamGroup)
        editDialog.handleOpen(teamGroup)
    }, [editDialog])

    const handleSelectedTeamChange = useCallback(
        (selection) => {
            setSelectedTeams(selection)
        }, [],
    )

    const handleDeleteTeamGroup = useCallback((id: string) => {
        console.log("Delete Team Group", id)
        if (!id) {
            return toast.error('Please select a team group')
        }
        if (!organization?.id) return
        dispatch(thunks.deleteTeamGroup(organization.id, { id }))
    }, [dispatch, organization?.id])


    const handleUpdateTeamGroup = useCallback(async (id: string, teamIds: string[]) => {
        if (!organization?.id) return
        dispatch(thunks.updateTeamGroup(organization?.id, { id, teamIds }))
        editDialog.handleClose()
    }, [dispatch, editDialog, organization?.id])

    const handleCreateClick = useCallback(
        () => {
            if (!organization?.role) {
                toast.error("Invalid organization: " + organization?.role)
                return
            }
            if (selectedTeams.length === 0) {
                toast.error("No teams selected")
                return
            }
            if (!batchName) {
                toast.error("No batch name")
                return
            }
            dispatch(thunks.createTeamGroup({
                name: batchName,
                organizationId: organization?.id,
                teamIds: selectedTeams,
            }))
            resetValues()
        }, [batchName, dispatch, organization?.id, organization?.role, resetValues, selectedTeams])

    const handleBatchNameChange = useCallback((e) => {
        setBatchName(e.target.value)
    }, [])


    return (
        <>
            <Stack
                spacing={4}
                p={3}
            >
                <Card>
                    <CardContent>
                        <Stack spacing={4}>
                            <Typography variant='h6' >What are batches?</Typography>
                            <Typography>
                                Batches serve as a practical organizational tool, allowing you to group teams together for streamlined file sharing and efficient management. This simplifies the process of handling numerous teams collectively, ensuring effective communication and collaboration.
                            </Typography>
                        </Stack>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Stack spacing={2}
                            direction={'column'}
                        >
                            <Typography
                                gutterBottom
                                variant="h5"
                            >
                                Create batch
                            </Typography>
                            <TextField label='Name'
                                value={batchName}
                                onChange={handleBatchNameChange}
                            ></TextField>
                            <div>
                                <Button variant='contained'
                                    color='secondary'
                                    onClick={dialog.handleOpen}
                                    startIcon={<SvgIcon>
                                        <PlusIcon></PlusIcon>
                                    </SvgIcon>}
                                    size='large'>Add teams</Button>
                            </div>
                            <Typography>You have selected: {selectedTeams.length}</Typography>
                            <div
                                style={{ marginLeft: 'auto' }}
                            >
                                <Button variant='contained'
                                    color='primary'
                                    onClick={handleCreateClick}
                                >Create</Button>
                            </div>
                        </Stack>
                    </CardContent>
                </Card>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Teams</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {batches.map(batch => {
                            return (
                                <TableRow key={batch.id}>
                                    <TableCell>
                                        {batch.name}
                                    </TableCell>
                                    <TableCell>
                                        {batch.teamIds ? batch.teamIds.length : 0}
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction={'row-reverse'}
                                            spacing={2}>
                                            <ConfirmPopover
                                                title=''
                                                onConfirm={() => handleDeleteTeamGroup(batch.id)}
                                                onCancel={() => { }}
                                                component={(handleClick) => <IconButton
                                                    size="small"
                                                    color="error"
                                                    onClick={e => handleClick(e)}
                                                    title='Delete batch'
                                                >
                                                    <Trash02Icon></Trash02Icon>
                                                </IconButton>}
                                                content={<Typography>This will delete the <b>batch</b> and all it's information</Typography>}
                                            />
                                            <Button variant='contained'
                                                color='primary'
                                                onClick={() => handleEditTeamGroupClick(batch)}
                                            >Edit</Button>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </Stack >

            {editTeamGroup && <OrganizationBatchesEditModal
                dialog={editDialog}
                onSave={handleUpdateTeamGroup}
                selectedTeams={editTeamGroup.teamIds}
                teamsResult={teamsResult}
            ></OrganizationBatchesEditModal>}

            <Dialog
                open={dialog.open}
                onClose={dialog.handleClose}
                fullWidth
            >
                <DialogContent>
                    <OrganizationSelectTeam
                        defaultSelectedTeams={selectedTeams}
                        onSelectionChanged={handleSelectedTeamChange}
                        allTeams={teamsResult.team.listTeams}></OrganizationSelectTeam>
                </DialogContent>
                <DialogActions>
                    <Button onClick={dialog.handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
