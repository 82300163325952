import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { FormSubmissionsByProcessModuleQuery, ListFieldsQuery, ListFormsQuery, ListProcessModulesQuery, TeamModuleConfigurationInput } from "src/API";
import { listFields, listForms, listProcessModules } from "src/graphql/queries";
import { DynamicTableData } from 'src/pages/modules/SRM/FormView';
import { ActivityEvent } from 'src/types/activity-event';
import { UploadFile } from 'src/types/UploadFile';
import { formSubmissionsByProcessModule } from '../../graphql/queries';
import PlanvasBaseApi from "../api";
import { ApiResponse } from '../team/ApiResponse';

export interface TeamModules {
    [key: string]: {
        steps: string[]
    }
}

export interface DeleteFileInput {
    teamId: string,
    key: string,
    formSubmissionId: string,
    fieldId: string
}


export interface FormFieldValueBase {
    value: any
}
export interface FileUploadSettings extends FormFieldValueBase {
    label: string;
}

export interface CommentSettings extends FormFieldValueBase {
    label: string;
}

export interface LinkSettings extends FormFieldValueBase {
    label: string;
}

export type FormFieldValue = File | null | undefined | string | number | UploadFile[] | DynamicTableData[] | { [key: string]: boolean } | ActivityEvent[]

export interface FormField {
    fieldID: string;
    value: FormFieldValue//FileUploadSettings | CommentSettings | LinkSettings | File | null | undefined | string | number | UploadFile[] | DynamicTableData[]
}

export interface createFormSubmission {
    processModuleId: string;
    formId: string;
    formFields: FormField[]
}

export interface FileUploadInput {
    formId: string,
    fieldId: string,
    formData: FormData
    processModuleId: string
}

export interface ProcessModuleOverviewResponse {
    processModuleId: string;
    processModuleName: string;
    teamForms: { [key: string]: TeamForm[] };
}

export interface TeamForm {
    formId: string;
    formName: string
    status: string;
    sequence: number;
}


class ProcessModuleApi extends PlanvasBaseApi {
    endpoint = "/processmodule"

    moduleConfigurationsPath = (teamId: string) => (`/team/moduleConfigurations/${teamId}`)
    createFormSubmissionPath = (teamId: string) => (`/team/${teamId}/formSubmission`)
    uploadFile = (teamId: string) => (`/team/${teamId}/file`)


    async getOverView(): ApiResponse<ProcessModuleOverviewResponse[]> {
        return super.get(`${this.endpoint}/overview`)
    }

    // gets processModules from backend
    async getProcessModules() {
        return API.graphql<GraphQLQuery<ListProcessModulesQuery>>(graphqlOperation(listProcessModules))
            .then((response) => {
                return response.data?.listProcessModules?.items
            })
    }

    async updateModuleConfigurationForTeam(teamId: string, moduleConfigurations: TeamModuleConfigurationInput[]) {
        return super.put(this.moduleConfigurationsPath(teamId), { moduleConfigurations })
    }

    async getFormsForProcessModule(): Promise<GraphQLResult<GraphQLQuery<ListFormsQuery>>> {
        return API.graphql<GraphQLQuery<ListFormsQuery>>(graphqlOperation(listForms))
    }

    async getFormSubmissionByProcessModuleId(processModuleId: string) {
        console.log('getFormSubmissionByProcessModuleId', processModuleId)
        return API.graphql<GraphQLQuery<FormSubmissionsByProcessModuleQuery>>(graphqlOperation(formSubmissionsByProcessModule, {
            processModuleID: processModuleId
        }))
    }

    async getFields(): Promise<GraphQLResult<GraphQLQuery<ListFieldsQuery>>> {
        return API.graphql<GraphQLQuery<ListFieldsQuery>>(graphqlOperation(listFields))
    }

    async createFormSubmission(teamId: string, input: createFormSubmission) {
        return super.post(this.createFormSubmissionPath(teamId), input)
    }

    async createFile(teamId: string, input: FileUploadInput, onProgress: (progressEvent: ProgressEvent) => void) {
        const { formId, fieldId, formData, processModuleId } = input;
        return super.postWithProgress(this.uploadFile(teamId), formData, {
            "Content-Type": "multipart/form-data"
        }, onProgress, {
            params: {
                formId,
                fieldId,
                processModuleId
            }
        })
    }

    async deleteFile(input: DeleteFileInput) {
        return super.delete(`/team/${input.teamId}/file`, {
            key: input.key,
            formSubmissionId: input.formSubmissionId,
            fieldId: input.fieldId
        })
    }

    async getPresignedUrl(teamId, key: string) {
        return super.get(`/team/file/presignedUrl`, {
            key,
            teamId
        })
    }


}
export const processModuleApi = new ProcessModuleApi();