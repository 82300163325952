import toast from 'react-hot-toast';
import { FormSubmission, ProcessModule, TeamModuleConfigurationInput } from "src/API";
import { DeleteFileInput, createFormSubmission, processModuleApi } from "src/api/process-module";
import $log from 'src/log/log';
import { slice } from "src/slices/process-module";
import { slice as teamSlice } from 'src/slices/team';
import { AppThunk } from "src/store";
import { FileUploadInput } from '../api/process-module/index';
import { create } from 'lodash';



const getProcessModules = (): AppThunk => async (dispatch): Promise<void> => {
    const modules = await processModuleApi.getProcessModules()
    if (!modules) return;
    dispatch(slice.actions.getProcessModules(modules.filter(d => Boolean(d)) as ProcessModule[]))
}

const updateProcessModuleConfigurationForTeam = (teamId: string, config: TeamModuleConfigurationInput[]): AppThunk => async (dispatch): Promise<void> => {
    const result = await processModuleApi.updateModuleConfigurationForTeam(teamId, config)
    if (!result) return;
    dispatch(teamSlice.actions.updateTeamModuleConfiguration(config))
}

const getFormsForProcessModule = (processModuleId: string): AppThunk => async (dispatch): Promise<void> => {
    const forms = await processModuleApi.getFormsForProcessModule()
    if (!forms) return;
    dispatch(slice.actions.getFormsForProcessModule(forms))
}

const getFields = (): AppThunk => async (dispatch): Promise<void> => {
    const fields = await processModuleApi.getFields()
    if (!fields) return;
    dispatch(slice.actions.getFields(fields))
}

const createFormSubmissionFunction = (teamId: string, input: createFormSubmission): AppThunk => async (dispatch): Promise<void> => {
    const createResult = await processModuleApi.createFormSubmission(teamId, input)
    if (!createResult || !createResult.ok) {
        toast.error('Failed submitting form')
        return
    }
    toast.success('Updated successfully')
    dispatch(slice.actions.updateFormSubmissions([createResult.result] as FormSubmission[]))
}

const getFormSubmissionByProcessModuleId = (processModuleId: string): AppThunk => async (dispatch): Promise<void> => {
    const formSubmissions = await processModuleApi.getFormSubmissionByProcessModuleId(processModuleId)
    if (!formSubmissions.data?.formSubmissionsByProcessModule?.items) {
        $log.debug('no form submissions found')
        return
    };
    console.log('gotten form submissions', formSubmissions)
    dispatch(slice.actions.updateFormSubmissions(formSubmissions.data?.formSubmissionsByProcessModule?.items as FormSubmission[]))
}

const uploadFile = (teamId: string, input: FileUploadInput, onProgress: (progressEvent: ProgressEvent) => void): AppThunk => async (dispatch): Promise<void> => {

    const fileResult = await processModuleApi.createFile(teamId, input, onProgress)
    if (fileResult.ok) {
        toast.success('Uploaded successfully')
    } else {
        toast.error('Upload failed')
    }
    dispatch(slice.actions.updateFormSubmissions([fileResult.result] as FormSubmission[]))
}

const deleteFile = (deleteFileInput: DeleteFileInput): AppThunk => async (dispatch): Promise<void> => {
    const deleteResult = await processModuleApi.deleteFile(deleteFileInput)
    if (deleteResult.ok) {
        toast.success('Deleted successfully')
    } else {
        toast.error('Delete failed')
    }
    dispatch(slice.actions.updateFormSubmissions([deleteResult.result] as FormSubmission[]))
}

export const thunks = {
    getProcessModules,
    updateProcessModuleConfigurationForTeam,
    getFormsForProcessModule,
    getFields,
    createFormSubmission: createFormSubmissionFunction,
    getFormSubmissionByProcessModuleId,
    uploadFile,
    deleteFile
}