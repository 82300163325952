import { NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, IconButton } from '@mui/material';
import { Stack, styled } from '@mui/system';
import ReverseLeft from '@untitled-ui/icons-react/build/esm/ReverseLeft';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';


const StyledIconButton = styled(IconButton)(({ theme }) => ({
    padding: theme.spacing(0.5),
    color: theme.palette.secondary.contrastText,
    background: theme.palette.primary.main,
    "&:hover": {
        background: theme.palette.primary.dark,
    }
}))

function dirname(path) {
    const separator = '/';
    const pathSegments = path.split(separator);
    pathSegments.pop();

    const directoryPath = pathSegments.join(separator) || separator;

    return directoryPath;
}

function getParentDirectories(dirname) {
    const separator = '/';
    if (!dirname) return [separator]
    const pathSegments = dirname.split(separator);
    const parentDirectories: string[] = [];

    let currentDirectory = '';

    for (let i = 0; i < pathSegments.length; i++) {
        const segment = pathSegments[i];
        if (i === pathSegments.length - 1) {
            // last element
            currentDirectory += segment;
        } else {
            currentDirectory += segment + separator;

        }
        parentDirectories.push(currentDirectory);
    }

    return [...new Set(parentDirectories)];
}
const getPathQuery = (path, additionalParams) => additionalParams ? `?${additionalParams}&path=${path}` : `?path=${path}`

export default function FileManagerBreadcrumb({ additionalParams = '' }) {
    const [searchParams, setSearchParams] = useSearchParams()
    const path = searchParams.get('path')
    const navigate = useNavigate();
    const parentDirectories = getParentDirectories(path)

    return (
        <>

            <Breadcrumbs
                separator={<NavigateNext fontSize="small" />}
                aria-label="breadcrumb">
                {
                    path !== "/" && (
                        <Stack direction="row"
                            spacing={1}>
                            <StyledIconButton
                                onClick={() => {
                                    navigate(getPathQuery(dirname(path), additionalParams))
                                }}
                            >
                                <ReverseLeft></ReverseLeft>
                            </StyledIconButton>
                        </Stack>
                    )
                }
                {
                    parentDirectories.map((parentDirectory, index) => {
                        return (
                            <Link
                                key={index}
                                to={getPathQuery(parentDirectory, additionalParams)}
                                style={{
                                    color: 'text.primary',
                                    textDecoration: 'none',
                                }}
                            >
                                {parentDirectory}
                            </Link>
                        )
                    })
                }
            </Breadcrumbs>
        </>
    )
}
