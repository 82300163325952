import { Button, Divider, IconButton, Input, Stack } from '@mui/material';
import Download03 from '@untitled-ui/icons-react/build/esm/Download03';
import Lock01Icon from '@untitled-ui/icons-react/build/esm/Lock01';
import LockUnlocked01 from '@untitled-ui/icons-react/build/esm/LockUnlocked01';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import { Canvas } from 'src/API';
import { DialogController } from 'src/hooks/use-dialog';
import { useDispatch } from 'src/store';
import { thunks } from 'src/thunks/canvas';
import { CanvasTemplateDialogData } from '.';
import { PermissionGuard } from '../../guards/organization-guard';
import { slice as canvasSlice } from "../../slices/canvas";

type CanvasMenuProps = {
    permissions: PermissionGuard
    handleLockClick,
    handleDownloadClick,
    locked: boolean,
    canvas: Canvas
    dialog: DialogController<CanvasTemplateDialogData>
    // onChatIconClick: () => void
}

export default function CanvasMenu({
    handleLockClick,
    handleDownloadClick,
    locked,
    canvas,
    permissions,
    dialog,
    // onChatIconClick
}: CanvasMenuProps) {

    const dispatch = useDispatch()
    const [canvasName, setCanvasName] = useState(canvas.name)
    const { canvasId, teamId } = useParams()
    const handleAddTile = () => {
        if (!canvasId || !teamId) return
        dispatch(canvasSlice.actions.createCanvasTile({
            canvasId,
            teamId
        }))
    }

    const handleNameChange = useCallback(() => {
        if (!canvas.id) return
        if (!canvasName) {
            toast.error('Canvas name cannot be empty')
            return setCanvasName(canvas.name)
        }
        if (canvas.name === canvasName) return
        dispatch(thunks.updateCanvas({
            id: canvas.id,
            name: canvasName
        }))

    }, [dispatch, canvas, canvasName])

    const handleCreateTemplate = useCallback(() => {
        dialog.handleOpen({
            canvas
        })
    }, [dialog, canvas])
    return (
        <Stack
            direction={'row'}
        >

            <Stack
                direction={'row'}
                spacing={1}
                p={1}
            >
                <Input
                    // defaultValue={canvas?.name}
                    fullWidth
                    value={canvasName}
                    disableUnderline
                    onChange={(e) => setCanvasName(e.target.value)}
                    onBlur={handleNameChange}
                    sx={{
                        '& .MuiInputBase-input': {
                            borderRadius: 1.5,
                            fontWeight: 500,
                            overflow: 'hidden',
                            fontSize: 16,
                            px: 2,
                            // py: 1,
                            textOverflow: 'ellipsis',
                            wordWrap: 'break-word',
                            '&:hover, &:focus': {
                                backgroundColor: (theme) => theme.palette.mode === 'dark'
                                    ? 'neutral.800'
                                    : 'neutral.100'
                            }
                        }
                    }}
                ></Input>

            </Stack>
            <Stack
                sx={{
                    marginLeft: 'auto'
                }}
                direction={'row'}
                alignItems={'center'}
                justifyContent={'end'}
            >
                <div>
                    {permissions.canEdit && (
                        <Button
                            size='small'
                            variant='contained'
                            onClick={handleAddTile}
                            startIcon={<PlusIcon></PlusIcon>}
                        >Add Tile</Button>)}
                </div>
                <Divider orientation='vertical'></Divider>
                <IconButton
                    color={locked ? 'secondary' : 'primary'}
                    title={!locked ? 'Lock tiles' : 'Unlock tiles'}

                    onClick={handleLockClick}
                >
                    {locked ? <Lock01Icon /> : <LockUnlocked01 />}
                </IconButton>
                <Divider orientation='vertical' ></Divider>
                {/* <IconButton
                    color={'primary'}
                    onClick={onChatIconClick}
                >
                    <ChatBubbleOutline />
                </IconButton> */}
                <Divider orientation='vertical' ></Divider>
                <Button
                    color='secondary'
                    startIcon={<Download03></Download03>}
                    onClick={handleDownloadClick}
                >Download to PDF</Button>
                <Divider orientation='vertical' ></Divider>
                {permissions.isOrganization ? (
                    <Button
                        onClick={handleCreateTemplate}
                        color='secondary'
                        startIcon={<PlusIcon></PlusIcon>}
                    >Create template</Button>
                ) : undefined}
            </Stack >
        </Stack>
    )
}
