import { List, ListItem, Switch, TextField } from '@mui/material';
import { Stack } from "@mui/system";
import { useCallback, useEffect, useState } from 'react';
import { Team } from 'src/API';
import { ListUtils } from 'src/utils/list-utils';

export default function OrganizationSelectTeam({ allTeams, onSelectionChanged, defaultSelectedTeams }: { defaultSelectedTeams: string[], allTeams: Team[], onSelectionChanged: (selection: string[]) => void }) {

    const [teams, setT] = useState(allTeams)
    const [filter, setFilter] = useState('')
    const [selection, setSelections] = useState<string[]>(defaultSelectedTeams || [])

    const setTeams = useCallback(
        (teams) => {
            setT([...teams].sort((a, b) => ListUtils.sortAsc(a.name.toLowerCase(), b.name.toLowerCase())))
        },
        [],
    )

    useEffect(() => {
        setTeams(allTeams)
    }, [allTeams, setTeams])

    const handleFilterChange = useCallback((event) => {
        setFilter(event.target.value)
        if (event.target.value === '') {
            setTeams(allTeams)
        } else {
            setTeams(allTeams.filter(t => t.name.toLowerCase().includes(filter.toLowerCase())))
        }
    }, [allTeams, filter, setTeams]);

    const handleSwitchClick = useCallback(
        (e) => {
            const value = e.target.value
            let newSelection = [...selection]
            if (selection.includes(value)) {
                newSelection = selection.filter(s => s !== value)
            } else {
                newSelection = [...selection, value]
            }
            setSelections(newSelection)
            onSelectionChanged(newSelection)

        }, [onSelectionChanged, selection])


    return (
        <Stack
            spacing={4}
            p={3}
        >
            <TextField label='Filter'
                onChange={handleFilterChange}></TextField>
            <List>
                {teams?.map((team, index) => (
                    <ListItem
                        color={index % 2 === 0 ? '#000' : '#fff'}
                        key={'batch-' + team.id}
                        secondaryAction={
                            <Switch value={team.id}
                                defaultChecked={defaultSelectedTeams.includes(team.id)}
                                onClick={handleSwitchClick}></Switch>}
                        title={team.name}
                    >
                        {team.name}
                    </ListItem>
                ))}
            </List>
        </Stack >
    )
}
