import { Auth } from 'aws-amplify';
import toast from 'react-hot-toast';
import { UpdateUserInput } from 'src/API';
import { CreateUserRequest, userApi } from 'src/api/user';
import $log from 'src/log/log';
import { slice } from 'src/slices/user';
import { organizationApi } from '../api/organizations/index';
import { AppThunk } from '../store/index';



const getUserGroups = (): AppThunk => async (dispatch): Promise<void> => {
    const userData = await Auth.currentAuthenticatedUser()
    const groups = userData.signInUserSession.accessToken.payload["cognito:groups"]
    dispatch(slice.actions.setGroups(groups))
}

const getOrganizations = (): AppThunk => async (dispatch): Promise<void> => {
    $log.debug('get_organizations user')
    const result = await organizationApi.getOrganizations()
    if (result.ok) {
        const organizations = result.result
        dispatch(slice.actions.setOrganizations(organizations))
        dispatch(slice.actions.setSuccess({
            message: "hello",
            attribute: 'organizations'
        }))
    } else {
        toast.error('Error getting organizations')
        dispatch(slice.actions.setError({ attribute: 'organizations', message: 'Error getting organizations' }))
    }
}

const updateUser = (request: UpdateUserInput): AppThunk => async (dispatch): Promise<void> => {
    const user = await userApi.updateUser(request)
    if (!user?.data?.updateUser) {
        toast.error('Error updating user')
        return
    }
    dispatch(slice.actions.updateAccount(user.data.updateUser))
}

const createUser = (request: CreateUserRequest): AppThunk => async (dispatch): Promise<void> => {
    const user = await userApi.createUser(request)
    if (!user?.ok) {
        toast.error('Error creating user')
        return
    }
    dispatch(slice.actions.updateAccount(user.result.createUser))
}

const getAccount = (userId: string): AppThunk => async (dispatch): Promise<void> => {
    const result = await userApi.getUser({
        id: userId
    })
    dispatch(slice.actions.getAccount(result?.data))
}

export const thunks = {
    getUserGroups,
    getOrganizations,
    updateUser,
    createUser,
    getAccount
}