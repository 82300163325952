import { PaletteColor, Theme, darken, lighten } from "@mui/material";
import { NavColor } from "src/types/settings";
import { withAlphas } from "../colors";
import { ColorPreset } from "..";
import { alpha } from '@mui/material/styles';


type Customers = 'uic' | 'umea'

export const getPrimaryCustomerColor = (customer: Customers): PaletteColor => {
    const colors: Record<Customers, any> = {
        'uic': withAlphas({
            lightest: '#F6FEF9',
            light: '#EDFCF2',
            main: '#960a37',
            dark: lighten("#960a37", 0.2),
            darkest: lighten("#960a37", 0.4),
            contrastText: '#FFFFFF'
        }),
        'umea': withAlphas({
            lightest: '#F6FEF9',
            light: '#EDFCF2',
            main: '#577F72',
            dark: lighten("#577F72", 0.2),
            darkest: lighten("#577F72", 0.4),
            contrastText: '#FFFFFF'
        })
    }
    return colors[customer]
}

export const getSecondaryCustomerColor = (customer?: ColorPreset): PaletteColor | undefined => {
    const colors = {
        'uic': withAlphas({
            lightest: '#F6FEF9',
            light: '#EDFCF2',
            main: "#b40050",
            dark: lighten("#b40050", 0.2),
            darkest: darken("#b40050", 0.1),
            contrastText: '#FFFFFF'
        }),
        'umea': withAlphas({
            lightest: '#F6FEF9',
            light: '#EDFCF2',
            main: "#577F72",
            dark: lighten("#577F72", 0.2),
            darkest: darken("#577F72", 0.1),
            contrastText: '#FFFFFF'
        })
    }
    if (!customer) return undefined;
    if (Object.keys(colors).includes(customer)) return colors[customer]
    return undefined
}

export const customerSidebarColors = (color: Customers, theme: Theme) => {
    const colors: Record<Customers, any> = {
        uic: {
            '--nav-bg': lighten("#960a37", 1),
            '--nav-color': theme.palette.common.black,
            '--nav-border-color': "#960a37",//'transparent',
            '--nav-logo-border': theme.palette.common.white,
            '--nav-section-title-color': "#c86400",
            '--nav-item-color': theme.palette.common.black,//theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': "#e61e73",
            '--nav-item-disabled-color': theme.palette.neutral[200],
            '--nav-item-icon-color': theme.palette.common.black,//theme.palette.neutral[400],
            '--nav-item-icon-active-color': "#e61e73",
            '--nav-item-icon-disabled-color': theme.palette.neutral[200],
            '--nav-item-chevron-color': theme.palette.common.black,
            '--nav-scrollbar-color': theme.palette.neutral[400],
            '--top-nav-bg-color': 'pink'
        },
        'umea': {
            '--nav-bg': '#253B52',
            '--nav-color': theme.palette.common.white,
            '--nav-border-color': theme.palette.common.black,
            '--nav-logo-border': theme.palette.common.white,
            '--nav-section-title-color': theme.palette.common.white,
            '--nav-item-color': theme.palette.common.white,//theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': "#D7B17C",
            '--nav-item-disabled-color': theme.palette.neutral[200],
            '--nav-item-icon-color': theme.palette.common.white,//theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.common.white,
            '--nav-item-icon-disabled-color': theme.palette.neutral[200],
            '--nav-item-chevron-color': theme.palette.common.black,
            '--nav-scrollbar-color': theme.palette.neutral[400],
            '--top-nav-bg-color': theme.palette.common.black
        }
    }
    return colors[color]
}

export const customerTopNavColors = (color: NavColor, theme: Theme) => {
    switch (color) {
        case 'uic':
            return {
                '--top-nav-border-color': '#b40050',
                '--top-nav-color': theme.palette.common.white,
                '--top-nav-bg': 'linear-gradient(90deg, rgba(180,0,80,1) 0%, rgba(230,30,115,1) 50%)'
            }
    }
    return {
        '--top-nav-border-color': theme.palette.common.black,
        '--top-nav-color': theme.palette.common.white,
        '--top-nav-bg': theme.palette.background.default
    }

}