import { Icon, IconButton } from '@mui/material';
import { Stack, styled } from '@mui/system';
import Globe01 from '@untitled-ui/icons-react/build/esm/Globe01';
import Lock01 from '@untitled-ui/icons-react/build/esm/Lock01';
import Share01 from '@untitled-ui/icons-react/build/esm/Share01';
import { useCallback, useMemo } from 'react';
import { UserAvatar } from 'src/components/user-avatar';
import { Item } from 'src/types/file-manager';
import { UserRecords } from '../team/team-table';

interface Props {
    item: Item, users: UserRecords,
    onShare?: (item: Item) => void
}
export type PermissionType = 'adminGroups' | 'viewerGroups' | 'adminUsers'
export const useSharedItem = ({ item, users }: { item: Item, users: UserRecords }) => {
    const shared = useMemo(() => ({
        groups: {
            isAdmin: item.adminGroups && item.adminGroups.length > 0,
            // isEditor: item.editorGroups && item.editorGroups.length > 0,
            isViewer: item.viewerGroups && item.viewerGroups.length > 0,
        },
        users: {
            isAdminUsers: item.adminUsers && item.adminUsers.length > 0,
            admins: item.adminUsers?.map(user => users[user]),
            owner: users[item.owner]
        }
    }), [item.adminGroups, item.adminUsers, item.owner, item.viewerGroups, users])

    const groupHasPermission = useCallback((type: PermissionType, groupId: string) => {
        return item[type].includes(groupId)
    }, [item])


    return { shared, groupHasPermission }

}

const StyledIcon = styled(Icon)(({ theme }) => ({
    color: theme.palette.primary.main,
    width: 30,
    height: 30,
}));

export default function SharedStatus({ item, users, onShare }: Props) {
    const { shared } = useSharedItem({ item, users })
    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
            >
                <IconButton
                    title="Share"
                    onClick={() => onShare && onShare(item)}
                >
                    <Share01></Share01>
                </IconButton>
                {shared.users.owner?.account && (<UserAvatar title={shared.users.owner.account.displayName}
                    config={shared.users.owner?.account.avatar} />)}
                {
                    (shared.groups.isAdmin || shared.groups.isViewer) ? (
                        <StyledIcon
                            title={'Shared'}
                        >
                            <Globe01></Globe01>
                        </StyledIcon>
                    ) : (
                        <StyledIcon
                            title={'Private'}
                        >
                            <Lock01></Lock01>
                        </StyledIcon>
                    )
                }
            </Stack>
        </>
    )
}
