import {
  AvatarGroup,
  Box,
  Card,
  Checkbox,
  Divider,
  IconButton,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material';
import DotsVerticalIcon from '@untitled-ui/icons-react/build/esm/DotsVertical';
import Download01 from '@untitled-ui/icons-react/build/esm/Download01';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useParams } from 'react-router';
import { usePopover } from 'src/hooks/use-popover';
import type { Item } from 'src/types/file-manager';
import { bytesToSize } from 'src/utils/bytes-to-size';
import { UserRecords } from '../team/team-table';
import { ItemIcon } from './item-icon';
import { ItemMenu } from './item-menu';
import SharedStatus from './shared-status';
import { onDownloadClick } from './utils/getDownloadLink';

interface ItemListCardProps {
  item: Item;
  onDelete?: (itemId: string) => void;
  onFavorite?: (itemId: string, value: boolean) => void;
  onOpen?: (item: Item) => void;
  onShare?: (item: Item) => void;
  onItemChecked?: (item: Item) => void
  users: UserRecords
}

export const ItemListCard: FC<ItemListCardProps> = (props) => {
  const { item, onDelete, onFavorite, onOpen, users, onShare, onItemChecked } = props;
  const popover = usePopover<HTMLButtonElement>();
  const { teamId } = useParams()

  const handleDelete = useCallback(
    (): void => {
      popover.handleClose();
      onDelete?.(item.id);
    },
    [item, popover, onDelete]
  );

  let size = item.size && bytesToSize(item.size);

  // if (item.type === 'folder') {
  //   size += `• ${item.itemsCount} items`;
  // }
  const createdAt = item.created && format(new Date(item.created), 'MMM dd, yyyy');
  const showShared = false //!item.isPublic && (item.shared || []).length > 0;
  const createdBy = item.createdBy && users[item.createdBy];

  return (
    <>
      <Card
        key={item.id}
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 0,
          transition: (theme) => theme.transitions.create(['background-color, box-shadow'], {
            easing: theme.transitions.easing.easeInOut,
            duration: 200
          }),
          '&:hover': {
            backgroundColor: 'background.paper',
            boxShadow: 16
          }
        }}
        variant="outlined"
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={3}
          sx={{
            pt: 2,
            px: 2
          }}
        >
          {/* <IconButton onClick={() => onFavorite?.(item.id, !item.isFavorite)}>
            <SvgIcon
              fontSize="small"
              sx={{ color: item.isFavorite ? 'warning.main' : 'action.active' }}
            >
              <Star01Icon />
            </SvgIcon>
          </IconButton> */}
          <IconButton
            onClick={popover.handleOpen}
            ref={popover.anchorRef}
          >
            <SvgIcon fontSize="small">
              <DotsVerticalIcon />
            </SvgIcon>
          </IconButton>
          {(!item.features || item.features?.canCheck) && <Checkbox onClick={e => { onItemChecked?.(item) }}></Checkbox>}
        </Stack>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: 'flex',
              mb: 1
            }}
          >
            <Box
              onClick={() => onOpen?.(item)}
              sx={{
                display: 'inline-flex',
                cursor: 'pointer'
              }}
            >
              <ItemIcon
                type={item.__typename}
                extension={item.name.split('.').pop()}
              />

            </Box>
          </Box>
          <Stack
            direction='row'
            alignItems='end'
            justifyContent={'end'}
          >

            {
              item.__typename && item.__typename === 'File' && (
                <IconButton
                  onClick={() => onDownloadClick(item, teamId)}
                  title='Download'
                >
                  <Download01></Download01>
                </IconButton>
              )
            }
          </Stack>
          <Typography
            onClick={() => onOpen?.(item)}
            sx={{ cursor: 'pointer' }}
            variant="subtitle2"
          >
            {item.name}
          </Typography>

          <Divider sx={{ my: 1 }} />
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={1}
          >
            {
              size && (
                <div>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                  >
                    {size}
                  </Typography>
                </div>
              )
            }
            <div>
              {/* {item.isPublic && (
                <Tooltip title="Public">
                  <Avatar
                    sx={{
                      height: 32,
                      width: 32
                    }}
                  >
                    <SvgIcon fontSize="small">
                      <Globe01Icon />
                    </SvgIcon>
                  </Avatar>
                </Tooltip>
              )} */}
              <SharedStatus
                item={item}
                onShare={onShare}
                users={users} ></SharedStatus>
              {showShared && (
                <AvatarGroup max={3}>
                  {/* {item.shared?.map((person) => (
                    <Avatar
                      key={person.name}
                      src={person.avatar}
                      sx={{
                        height: 32,
                        width: 32
                      }}
                    />
                  ))} */}
                </AvatarGroup>
              )}
            </div>
          </Stack>
          <Stack>
            <Typography
              color="text.secondary"
              variant="caption"
            >
              Created at {createdAt}
            </Typography>
            {createdBy && (<Typography
              color="text.secondary"
              variant="caption"
            >
              Created by {createdBy.account?.displayName}
            </Typography>)}
          </Stack>
        </Box>
      </Card>
      <ItemMenu
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        onDelete={handleDelete}
        open={popover.open}
      />
    </>
  );
};

ItemListCard.propTypes = {
  // @ts-ignore
  item: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  onFavorite: PropTypes.func,
  onOpen: PropTypes.func
};
