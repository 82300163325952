import { GraphQLQuery } from "@aws-amplify/api";
import { API, graphqlOperation } from 'aws-amplify';
import { CreateTeamGroupInput, DeleteTeamGroupInput, ListTeamGroupsQuery, TeamGroupByOrganizationQuery, UpdateTeamGroupInput } from "src/API";
import { listTeamGroups, teamGroupByOrganization } from "src/graphql/queries";
import PlanvasBaseApi from "../api";
import $log from 'src/log/log';

export type TeamGroupInput = {
    name: string
    teamIds: string[]
    organizationId: string
}



class TeamGroup extends PlanvasBaseApi {
    endpoint = (organizationId: string) => `/organization/${organizationId}/team-group`

    create(input: Omit<CreateTeamGroupInput, 'organizationRole'>) {
        const { name, teamIds, organizationId } = input;
        return super.post(this.endpoint(input.organizationId), {
            name, teamIds, organizationId
        })
    }

    update(organizationId:string,input: UpdateTeamGroupInput) {
        return super.put(`${this.endpoint(organizationId)}`, input)
    }

    delete(organizationId: string, input: DeleteTeamGroupInput) {
        return super.delete(`${this.endpoint(organizationId)}/${input.id}`, {})
    }

    listByOrganization(id: string) {
        return API.graphql<GraphQLQuery<TeamGroupByOrganizationQuery>>(graphqlOperation(teamGroupByOrganization,
            {
                organizationId: id
            }))
    }
}

export const teamGroupApi = new TeamGroup();